// HeroSection.js
import React from 'react';
import './Info.css';
import { Parallax } from 'react-parallax';
import info from '../../images/100.jpg'
import { Link } from 'react-router-dom';

const Info = () => {
  return(
    <>
    <Parallax className='info-parallax' strength={600} bgImage={info}>
        <div className="info-section">
        <div className="container">
        <h1 className="heading">Do you need Emergency Medical Care? </h1>
          <p className="paragraph">
            We offer top-notch services not only to improve patient satisfaction but also contribute to the overall efficiency and reputation of the hospital.
          </p>
          <Link to='./contact' className="read-more">Contact Now</Link>
            
        </div>
        </div>
        <div className='overlay'></div>
    </Parallax>
    </>
  );
};

export default Info;
