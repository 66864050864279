import React, { useState } from 'react';
import './Oncology.css';
import OrganImage from '../OrganImage';
import Footer from '../../../elements/Footer/Footer';

const Nephrology = () => {
  const [activeTab, setActiveTab] = useState('tab-1');

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <>
    <OrganImage/>
    <div className='oncology-container'>
      <div className="oncology-tabs">
        <input
          className="oncology-input"
          name="tabs"
          type="radio"
          id="tab-1"
          checked={activeTab === 'tab-1'}
          onChange={() => handleTabClick('tab-1')}
        />
        <label className="oncology-label" htmlFor="tab-1">Kidney Transplant</label>
        <div className="oncology-panel">
          <h1>What Is Kidney?</h1>
          <div className="separator"></div>
          <p>Kidney is a vital organ in the human body responsible for filtering the blood and excreting metabolic by-products. These metabolic by products are passed off as urine. The kidney is also responsible for electrolyte balance and regulation of blood pressure.</p>
          <p>Kidney diseases refers to conditions and problems with the kidneys and its function. Kidney diseases are majorly of two types; acute kidney disease and chronic kidney disease.

            In acute kidney diseases, kidney function is lost abruptly and can occur due to a variety of causes while in chronic kidney disease, the kidney degenerates slowly up until its functions are impaired. Research have shown that there is a rise in the prevalence of chronic kidney diseases (CKD).</p>
            <br></br><br></br>
          <h2 className='heading2'>What Is A Kidney Transplant?</h2>
          <div className="separator"></div>
          <p>A kidney transplant is an operation that places a healthy kidney from another person into your body. The kidney may come from someone who has died or from a living person who may be a close relative, spouse, or friend. It can even come from someone who wishes to donate a kidney to anyone in need of a transplant. Your new kidney will be placed in your lower abdomen and connected to your bladder and blood vessels. The transplant operation takes about 3 hours and you will be in the hospital for about 5 to 7 days. After the transplant, you will need to take special medications to prevent your body from rejecting the new kidney. You will have to take these medications for as long as you have the transplant. Many patients prefer a transplant over dialysis because it gives them more freedom, allows for a less restricted diet and may improve the quality and length of life. A kidney transplant is a treatment, not a cure. A person with a kidney transplant still has kidney disease, and may need some of the other medicines they took before the transplant</p>
            <br></br><br></br>
           <h2 className='heading2'>How Your Kidneys Are Checked</h2>
           <div className="separator"></div>
           <p>Two tests are used to check for kidney disease.</p>
           <li className='heading-li'>A blood test checks your GFR, which tells how well your kidneys are filtering.</li>
           <li className='heading-li'>A urine test checks for albumin in your urine, a sign of kidney damage.</li>
            <br></br><br></br>
           
            
        </div>


       



       
       {/* <input
          className="oncology-input"
          name="tabs"
          type="radio"
          id="tab-6"
          checked={activeTab === 'tab-6'}
          onChange={() => handleTabClick('tab-6')}
        />
        <label className="oncology-label" htmlFor="tab-6">Prostate Cancer</label>
        <div className="oncology-panel">
          <h1>Tekno Match</h1>
          <p>Tekno Match (Citrus ×clementina) is a hybrid between a mandarin orange and a sweet orange, so named in 1902. The exterior is a deep orange colour with a smooth, glossy appearance. Clementines can be separated into 7 to 14 segments. Similarly to tangerines, they tend to be easy to peel.</p>
        </div>
  */}
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default Nephrology;
