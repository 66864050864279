import React from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import './Serviceimage.css';
import image1 from '../../images/youngdoctorwoman-min.jpg';
import image2 from '../../images/caringdoctor-min.jpg';
import image3 from '../../images/appoint.jpg';
import image4 from '../../images/schedule-min.jpg';


const Serviceimage = () => {
  const slides = [
    { image: image1, heading: 'Coordination of All Medical Appointments: Simplifying Your Healthcare Journey', paragraph: '' },
    { image: image2, heading: 'Compassionate Care', paragraph: '' },
    { image: image3, heading: 'Health Check Appointment', paragraph: '' },
    { image: image4, heading: 'Appointment Schedule', paragraph: '' },

  ];

  return (
    <Carousel className='service-carousel'>
      {slides.map((slide, index) => (
        <Carousel.Item className='service-carousel-item' key={index}>
          <img
            className="d-block w-100"
            src={slide.image}
            alt={`Slide ${index + 1}`}
          />
          <div className="service-carousel-caption">
            <h3>{slide.heading}</h3>
            <p>{slide.paragraph}</p>
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default Serviceimage;
