import React from 'react'
import Footer from '../../elements/Footer/Footer'
import Aboutimage from '../../elements/AboutSection/Aboutimage'
import AboutHeroSection from '../../elements/AboutSection/AboutHeroSection'
import AboutText from '../../elements/AboutSection/AboutText'
import Objectives from '../../elements/ObjectiveSection/Objectives'
import Blog from '../../elements/Blog/Blog'
import Scroll from '../../elements/Scroll/Scroll'

const About = () => {
  return (
    <>
    <Aboutimage/>
    <AboutText/>
    <AboutHeroSection/>
    <Objectives/>
    <Blog/>
    <Scroll/>
    <Footer/>
      
    </>
  )
}

export default About
