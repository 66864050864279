import React, {useState} from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './TreatmentOb.css'; // Import CSS file
import cardiology from '../../images/cardiology.png'
import cardiov from '../../images/cardiov.png'
import gastroentrology from '../../images/gastroenterology.png'
import dermatology from '../../images/dermatology.png'
import endo from '../../images/endocrinology.png'
import ent from '../../images/ent.png'
import surgicalgas from '../../images/gastrosurgery.png'
import generalsurgery from '../../images/GENERAL_SURGERY.png'
import histopatho from '../../images/HISTOPATHOLOGY.png'
import nephrology from '../../images/NEPHROLOGY.png'
import neurology from '../../images/NEUROLOGY.png'
import neurosurgery from '../../images/NEUROSURGERY.png'
import gynaecology from '../../images/OBSTETRICS___GYNAECOLOGY.png'
import oncologysurgical from '../../images/ONCOLOGY_SURGICAL.png'
import oncologymed from '../../images/ONCOLOGY_MEDICAL.png'
import oncorad from '../../images/ONCOLOGY_RADIATION.png'
import orthopaedic from '../../images/ORTHOPAEDIC.png'
import pediatric from '../../images/PEDIATRIC.png'
import radiolo from '../../images/RADIOLOGY_RADIODIAGNOSIS.png'
import respo from '../../images/RESPIRATORY__CRITICAL_CARE___SLEEP_MEDICINE.png'
import rheuma from '../../images/RHEUMATOLOGY.png'
import uro from '../../images/uro.png'
import vascular from '../../images/VASCULAR___ENDOVASCULAR_SURGERY.png'

const services = [
  { 
    icon: cardiology , 
    title: 'CARDIOLOGY', 
    description: (
      <div className='description-container'>
        <p>Cardiothoracic vascular surgery is a specialized field of medicine focusing on surgical
        interventions for diseases affecting the heart, chest, and blood vessels. Common procedures
        include coronary artery bypass grafting (CABG), valve repair or replacement, aortic
        aneurysm repair, and peripheral vascular surgery.</p>
        <p>Before surgery, patients undergo thorough evaluations, including imaging tests like CT scans
        and angiograms, as well as cardiac catheterization to assess the severity of their condition.
        Treatment options are then discussed, which may include lifestyle changes, medications, or
        surgical intervention, depending on the specific diagnosis and patient's overall health.</p>
      </div>
    )
  },
  { 
    icon: cardiov , 
    title: 'CARDIO THORACIC VASCULAR SURGERY',
    description: (
      <div className='description-container'>
        <p>Liver Resection surgery involves various procedures on the liver to address different disorders. The most
        common operation entails excising a portion of the liver affected by disease. Liver resection is typically
        carried out to remove malignant tumors, either singular or multiple, localized to either the left or right
        side of the liver, with favorable survival rates.
        Patients undergoing liver resection undergo thorough evaluation by a multidisciplinary team to confirm
        the absence of extrahepatic tumors (outside the liver). Liver resections performed on patients with
        extrahepatic disease may alleviate symptoms caused by the tumor but offer limited improvement in
        survival.<br></br> Additionally, benign liver tumors such as cysts, adenomas, and hemangiomas can be effectively
        managed through liver resection. In cases where benign tumors are superficial and small in size,
        laparoscopic surgery may be performed, involving small abdominal punctures while utilizing a video
        camera for visualization.</p>

        <p>Liver resections are also conducted on individuals willing to donate part of their liver to a loved one,
          known as live donor liver transplant.
          A liver resection typically lasts between 3 to 5 hours and can often be performed without the need for
          blood transfusion, thus qualifying as a bloodless surgery. It is generally safe to remove up to 75% of the
          liver tissue. Hospitalization typically lasts about 5 days, with complete recovery occurring within 5 to 6
          weeks. The resected liver regenerates to its preoperative size within 6 to 8 weeks. Liver resections often
          yield excellent outcomes.<br></br>
          Experience exceptional and fulfilling medical care in Nepal and India with us. Share your medical history
          reports for personalized assistance by emailing them to abchealthcaresolutions10@gmail.com or
          complete the form below to receive prompt guidance from our consultants.</p>
      </div>
    )
  },
  { 
    icon: cardiov, 
    title: 'THORACIC AND VASCULAR SURGERY', 
    description: (
      <div className='description-container'>
        <p>Thoracic and vascular surgery focuses on surgical interventions for conditions affecting the
        chest (thoracic) and blood vessels (vascular). Common procedures include lung surgery,
        esophageal surgery, and surgery for diseases of the arteries and veins.</p>
        
      <p>Before surgery, patients undergo thorough evaluations, including imaging tests like CT scans
      and angiograms, to assess the severity of their condition. Treatment options are then
      discussed, which may include medications, minimally invasive procedures, or surgery,
      depending on the specific diagnosis and patient's overall health.</p>

      </div>
    )
  },
  { 
    icon: dermatology, 
    title: 'DERMATOLOGY',
    description: (
      <div className='description-container'>
        <p>Dermatology specialty focused on the diagnosis and treatment of skin, hair, and nail
        disorders, as well as conditions affecting mucous membranes. Common skin conditions
        treated by dermatologists include acne, eczema, psoriasis, and skin cancer.</p>
        <p>Before treatment, patients typically undergo a thorough examination of the skin, often
          including visual inspection, dermoscopy, or skin biopsy to confirm the diagnosis. Treatment
          options may include topical medications, oral medications, phototherapy, or procedural
          interventions such as cryotherapy, laser therapy, or surgical excision, depending on the
          specific condition and its severity.</p>
      </div>
    )
  },
  { 
    icon: endo, 
    title: 'ENDOCRINOLOGY',
    description: (
      <div className='description-container'>
        <p> Endocrinology focused on the diagnosis and treatment of disorders related to hormones and
        the endocrine system. Common conditions treated by endocrinologists include diabetes,
        thyroid disorders, adrenal disorders, and hormonal imbalances.</p>
        <p>Treatment preprocess typically involves a thorough evaluation, including medical history,
        physical examination, and diagnostic tests such as blood tests or imaging studies to assess
        hormone levels and gland function. Treatment options may include medications, hormone
        replacement therapy, lifestyle modifications, or surgical interventions, depending on the
        specific disorder and its underlying cause.</p>
      </div>
    )
  },
  { 
    icon: ent , 
    title: 'ENT',
    description: (
      <div className='description-container'>
        <p> </p>
        <p> </p>
       
      </div>
    )
  },
  { 
    icon: gastroentrology, 
    title: 'GASTROENTEROLOGY & HEPATOLOGY',
    description: (
      <div className='description-container'>
        <p> </p>
        <p> </p>
       
      </div>
    )
  },
  { 
    icon: surgicalgas , 
    title: 'SURGICAL GASTROENTEROLOGY',
    description: (
      <div className='description-container'>
        <p> </p>
        <p> </p>
       
      </div>
    )
  },
  { 
    icon: histopatho, 
    title: 'HISTOPATHOLOGY',
    description: (
      <div className='description-container'>
        <p> </p>
        <p> </p>
       
      </div>
    )
  },
  { 
    icon: nephrology, 
    title: 'NEPHROLOGY',
    description: (
      <div className='description-container'>
        <p> </p>
        <p> </p>
       
      </div>
    )
  },
  { 
    icon: neurology , 
    title: 'NEUROLOGY',
    description: (
      <div className='description-container'>
        <p> </p>
        <p> </p>
       
      </div>
    )
  },
  { 
    icon: neurosurgery , 
    title: 'NEUROSURGERY',
    description: (
      <div className='description-container'>
        <p> </p>
        <p> </p>
       
      </div>
    )
  },
  { 
    icon: gynaecology , 
    title: 'OBSTETRICS & GYNAECOLOGY',
    description: (
      <div className='description-container'>
        <p> </p>
        <p> </p>
       
      </div>
    )
  },
  { 
    icon: oncologysurgical , 
    title: 'ONCOLOGY SURGICAL',
    description: (
      <div className='description-container'>
        <p> </p>
        <p> </p>
       
      </div>
    )
  },
  { 
    icon: oncologymed , 
    title: 'ONCOLOGY MEDICAL',
    description: (
      <div className='description-container'>
        <p> </p>
        <p> </p>
       
      </div>
    )
  },
  { 
    icon: oncorad , 
    title: 'ONCOLOGY RADIATION',
    description: (
      <div className='description-container'>
        <p> </p>
        <p> </p>
       
      </div>
    )
  },
  { 
    icon: orthopaedic , 
    title: 'ORTHOPAEDIC',
    description: (
      <div className='description-container'>
        <p> </p>
        <p> </p>
       
      </div>
    )
  },
  { 
    icon: pediatric , 
    title: 'PEDIATRIC',
    description: (
      <div className='description-container'>
        <p> </p>
        <p> </p>
       
      </div>
    )
  },
  { 
    icon: pediatric , 
    title: 'PEDIATRIC UROLOGY & PEDIATRIC SURGERY',
    description: (
      <div className='description-container'>
        <p> </p>
        <p> </p>
       
      </div>
    )
  },
  { 
    icon: radiolo , 
    title: 'RADIOLOGY/ RADIODIAGNOSIS',
    description: (
      <div className='description-container'>
        <p> </p>
        <p> </p>
       
      </div>
    )
  },
  { 
    icon: respo , 
    title: 'RESPIRATORY, CRITICAL CARE & SLEEP MEDICINE',
    description: (
      <div className='description-container'>
        <p> </p>
        <p> </p>
       
      </div>
    )
  },
  { 
    icon: rheuma , 
    title: 'RHEUMATOLOGY',
    description: (
      <div className='description-container'>
        <p> </p>
        <p> </p>
       
      </div>
    )
  },
  { 
    icon: uro , 
    title: 'UROLOGY & ANDROLOGY',
    description: (
      <div className='description-container'>
        <p> </p>
        <p> </p>
       
      </div>
    )
  },
  { 
    icon: vascular , 
    title: 'VASCULAR & ENDOVASCULAR SURGERY',
    description: (
      <div className='description-container'>
        <p> </p>
        <p> </p>
       
      </div>
    )
  },

];

const ProcedureOb = () => {
  const [expanded, setExpanded] = useState({});

  const toggleDescription = (index) => {
    setExpanded(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  return (
    <section id="treatment-services">
      <Container>
        <h2 className="text-center mb-5 colored-heading" ></h2>
        <Row className="d-flex justify-content-center">
          {services.map((service, index) => (
            <Col key={index} xs={12} md={6} lg={3} className="treatment-service-item">
              <div>
                <img src={service.icon} alt={service.title} className="treatment-service-icon" /> 
                <h3 className="treatment-service-title">{service.title}</h3>
                <div className='treatment-service-description'>
                  {expanded[index] ? service.description : <p>{service.description.props.children[0]}</p>}
                </div>
                <Button variant="link" onClick={() => toggleDescription(index)} className='show-more-button'>
                  {expanded[index] ? 'Show Less' : 'Show More'}
                </Button>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default ProcedureOb;
