import React from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import './HomeSlider.css';
import image1 from '../../images/doctorsphoto-min.jpg';
import image2 from '../../images/surgeon.jpg';
import image3 from '../../images/youngdoctorwoman-min.jpg';
import image4 from '../../images/sideviewman-min.jpg';
import image5 from '../../images/frontgirl-min.jpg';
import image6 from '../../images/airam-min.jpg';
import image7 from '../../images/telemedicine-min.jpg';
import image8 from '../../images/calculatorgirl-min.jpg';

const HomeSlider = () => {
  const slides = [
    { image: image6, heading: 'Air Ambulance Service with Medical Team: Lifesaving Care in the Sky', paragraph: 'At ABC Healthcare Solutions, we understand that medical emergencies canarise anywhere, even in the air. Our Air Ambulance Service with a dedicatedmedical team ensures that critical patients receive the highest level of care while being transported swiftly to medical facilities.' },
    { image: image1, heading: 'Advance Doctor Team', paragraph: 'Your Trusted Partners in Advanced Medical Care. With a highly skilled and compassionate team of specialists, we are dedicated to delivering cutting-edge healthcare solutions tailored to your needs, ensuring your journey to wellness is met with expertise and empathy.' },
    { image: image2, heading: 'Best Transplant Service Provider', paragraph: 'ABC Healtcare Solutions brings you the best transplant services based on your needs. Our transplantation services include Kidney and Liver transplant.' },
    { image: image3, heading: 'Health Check Appointment: Prioritizing Your Wellness', paragraph: 'At ABC Healthcare Solutions, your well-being is our top priority. Our Health Check Appointment service facilitates comprehensive health assessments, tailored to your individual needs and medical history. We connect you with experienced healthcare professionals who specialize in preventive care, allowing you to take control of your health.' },
    { image: image4, heading: 'Accommodation Assistance for Patients & Companions: A Home Away from Home', paragraph: 'At ABC Healthcare Solutions, we understand that medical journeys often require a supportive and comfortable environment. Our Accommodation Assistance service is designed to ensure that both patients and their companions have a suitable place to stay during their healthcare journey.' },
    { image: image5, heading: 'Travel Assistance: Navigating Your Healthcare Journey with Ease', paragraph: 'Our Travel Assistance service is designed to take care of the logistical aspects of your healthcare journey. Whether it is co-ordinating flights accommodations, or transportation, we are here to make your travel experience as smooth as possible.' },
    { image: image7, heading: 'Telemedicine Consultations: Expert Care at Your Fingertips', paragraph: 'Through Telemedicine Consultations, we connect you with experienced healthcare professionals who can provide medical evaluations, advice, and recommendations remotely. This service ensures that you receive timely and personalized care, even if you are unable to visit a physical healthcare facility.' },
    { image: image8, heading:'Cost Estimate: Clarity in Healthcare Expenses', paragraph:'At ABC Healthcare Solutions, we understand that healthcare costs can be concern. Our Cost Estimate service is designed to provide you with transparent and accurate information about the expenses associated with your medical treatments and procedures.'}
  ];

  return (
    <Carousel interval={3000}> {/* Change the interval value to adjust the slide duration in milliseconds */}
      {slides.map((slide, index) => (
        <Carousel.Item key={index}>
          <img
            className="d-block w-100"
            src={slide.image}
            alt={`Slide ${index + 1}`}
          />
          <div className="carousel-caption">
            <h3>{slide.heading}</h3>
            <p>{slide.paragraph}</p>
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default HomeSlider;
