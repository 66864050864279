import React, { useState } from 'react';
import './Oncology.css';
import OrganImage from '../OrganImage';
import Footer from '../../../elements/Footer/Footer';

const Trransplant = () => {
  const [activeTab, setActiveTab] = useState('tab-1');

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <>
    <OrganImage/>
    <div className='oncology-container'>
      <div className="oncology-tabs">
        <input
          className="oncology-input"
          name="tabs"
          type="radio"
          id="tab-1"
          checked={activeTab === 'tab-1'}
          onChange={() => handleTabClick('tab-1')}
        />
        <label className="oncology-label" htmlFor="tab-1">Organ Transplant</label>
        <div className="oncology-panel">
          <h1>What Is An Organ Transplant?</h1>
          <div className="separator"></div>
          <p>
In simple words, Organ transplant is a procedure in which the organ is taken from one individual's body and redressed in another individual's body. Organ transplant is a method that is used to treat problems of the human body. These problems can be categorized as defective, damaged, or missing body organs. So, the procedure for organ transplant involves certain steps. In these steps, the organ is taken or removed from one person's body and then it is placed in the recipient or the patient's body to replace the damaged or missing organ with the healthy one that functions properly.</p>
          <p>The organ transplantation needs to be done when the problems or the disease of the organs cannot be treated with any medical drugs or surgeries. There are a number of organs of the human body that can be transplanted, some of them are listed below:</p>
           <li className='heading-li'>Lungs</li>
           <li className='heading-li'>kidneys</li>
           <li className='heading-li'>Pancreas</li>
           <li className='heading-li'>Intestine</li>
           <li className='heading-li'>Heart & Liver</li>

            <br></br><br></br>
          <h2 className='heading2'>Factors And Criteria That Affect Organ Transplant</h2>
          <div className="separator"></div>
          <p>Organ transplants cannot be done without proper consultations from the medical experts. It requires proper analysis as well as thorough examination of both candidates. That includes the donor as well as the recipient. The process and screening of both the subjects are done on the basis of logistical and medical characteristics. These logistical and medical characteristics are thoroughly measured for an organ so as to distribute and to find the best possibilities and criteria for the receiving candidate (recipient)</p>
            <br></br><br></br>
           <h2 className='heading2'>Organ Matching Process:</h2>
           <div className="separator"></div>
           <p>Organ matching process involves certain parameters or steps and these are as follows:</p>
           <li className='heading-li'>The very first step is to donate an organ.</li>
           <li className='heading-li'>In the next step the UNOS (United Network for Organ Sharing) lists the potential recipient for the organ transplant.</li>
           <li className='heading-li'>The third step involves notifying the transplant center about an available organ.</li>
           <li className='heading-li'>The transplant team processes the organ for the recipient.</li>
           <li className='heading-li'>The final steps include the acceptance or declination of the organ.</li>
           <p>Organ transplant requires authentication, and it has a waiting list. To be on the waiting list you need to register yourself on the national waiting list for the patient required to visit the transplant hospital. Then the physician will examine the patient carefully and thoroughly. After examination, the physician will determine the condition of the patient's health while considering his or her medical history as well. After this, the physician will decide if the patient is convincing enough to be listed or not.</p>
            <br></br><br></br>
            <h2 className='heading2'>What Is An Organ Distributing Policy?</h2>
            <div className='separator'></div>
            <p>After registering yourself on the national waiting list, the next thing will be the distribution of the organs. The distribution policy of organs is very simple. The first step of distribution of the organ is distributing organs in the local region. If there are no recipients in the local region then, the next distribution area tier changes to regional. If there is no recipient in the regional area then again, the area tier changes and at last it might become national. Hence now the availability of the organs is in terms of the whole nation. Thus, the idea is to provide the organ to the most appropriate recipient respectively.</p>
          
            <br></br><br></br>
            <h2 className='heading2'>What Are The Facts That Are Related To Organ Donation?</h2>
            <div className='separator'></div>
            <p>The facts that are related to organ donations are as follows:</p>
            <li className='heading-li'>Anyone that is above 18 years of age can donate his or her organ.</li>
            <li className='heading-li'>If the donor donates the organ after the death. Then a proper and detailed medical assessment needs to be done.</li>
            <li className='heading-li'>There are certain conditions that enable the exclusion of the organ donation is, if the donor is suffering from HIV or some serious infection, kidney disease, heart disease or any type of cancer.</li>
        </div>


       



       
       {/* <input
          className="oncology-input"
          name="tabs"
          type="radio"
          id="tab-6"
          checked={activeTab === 'tab-6'}
          onChange={() => handleTabClick('tab-6')}
        />
        <label className="oncology-label" htmlFor="tab-6">Prostate Cancer</label>
        <div className="oncology-panel">
          <h1>Tekno Match</h1>
          <p>Tekno Match (Citrus ×clementina) is a hybrid between a mandarin orange and a sweet orange, so named in 1902. The exterior is a deep orange colour with a smooth, glossy appearance. Clementines can be separated into 7 to 14 segments. Similarly to tangerines, they tend to be easy to peel.</p>
        </div>
  */}
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default Trransplant;
