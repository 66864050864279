import React from 'react'
import InternationalImage from './InternationalImage'
import InternationalText from './InternationalText'
import InternationalCard from './InternationalCard'
import Scroll from '../../elements/Scroll/Scroll'
import Footer from '../../elements/Footer/Footer'

const International = () => {
  return (
    <>
    <InternationalImage/>
    <InternationalText/>
    <InternationalCard/>
    <Scroll/>
    <Footer/>
      
    </>
  )
}

export default International
