import React from 'react'
import Footer from '../../elements/Footer/Footer'
import Scroll from '../../elements/Scroll/Scroll'
import CreateForm from '../../elements/Form/CreateForm'
import ContactText from './ContactText'
import ContactImage from './ContactImage'


const OurContact = () => {
  return (
    <>
      <ContactImage/>
      <ContactText/>
      <CreateForm/>
      <Scroll/>
      <Footer/>
    </>
  )
}

export default OurContact
