import React, { useState } from 'react';
import './Oncology.css';
import OrganImage from '../OrganImage';
import Footer from '../../../elements/Footer/Footer';

const Hematology = () => {
  const [activeTab, setActiveTab] = useState('tab-1');

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <>
    <OrganImage/>
    <div className='oncology-container'>
      <div className="oncology-tabs">
        <input
          className="oncology-input"
          name="tabs"
          type="radio"
          id="tab-1"
          checked={activeTab === 'tab-1'}
          onChange={() => handleTabClick('tab-1')}
        />
        <label className="oncology-label" htmlFor="tab-1">Bone Marrow Transplant</label>
        <div className="oncology-panel">
          <h1>Bone Marrow Transplant</h1>
          <div className="separator"></div>
          <p>Bone marrow transplantation is a surgical treatment that replaces diseased or injured bone marrow with healthy blood-forming stem cells. A stem cell transplant is another name for the bone marrow treatment. Bone marrow is a soft, spongy tissue that can be found in your bones. It is in charge of creating stem blood cells, which then give rise to red blood cells, white blood cells, and platelets, among other blood cells.</p>
          <p>A bone marrow transplant is a surgery in which blood stem cells are transplanted from the bone marrow to replace cells in the bone marrow that have been injured or killed. After the healthy blood stem cells have been transplanted, they travel to the bone marrow and begin to form new bone marrow cells, allowing cell creation to resume. It's possible that the stem cells will come from you or from a donor. The method of bone marrow transplantation is also known as bronchial cell transplantation.</p>
            <br></br><br></br>
          <h2 className='heading2'>What Is The Need For A Bone Marrow Transplant?</h2>
          <div className="separator"></div>
          <p>Bone marrow is a vital aspect of the body because it helps your immune system work properly. If your bone marrow is sick, and you don't have enough white blood cells, red blood cells, or platelets, your immune system will be compromised, and you'll be more susceptible to infections, diseases, and infections. This can be extremely harmful to your body and can lead to dangerous diseases. That is why a bone marrow transplant is done when the bone marrow isn't working properly, such as when you have a bone marrow illness, and the bone marrow malfunctions. The cause of bone marrow destruction or injury varies from person to person and diagnosing bone marrow disorder symptoms can be difficult at times.</p>
          <p>If your bone marrow is unable to produce stem cells, your bone marrow transplant physician may recommend a bone marrow transplant. Bone marrow transplants can help those with cancer-related illnesses as well as those who have had their bone marrow damaged by chemotherapy or radiation. Your bone marrow may be saved after it has been destroyed with the help of a bone marrow transplant, as fresh stem cells may speed up the stem cell creation process.</p>
            <br></br><br></br>
           <h2 className='heading2'>Types of Bone Marrow Transplant:</h2>
           <div className="separator"></div>
           <p>There are several different types of bone marrow transplants. The cost of a transplant is determined on the type of surgery required. The following are the many types of BMT treatment approaches:</p>
           <li className='heading-li'>Autologous Bone Marrow Transplant: Autologous refers to a person's own "self”. An autologous transplant is one that employs stem cells from the patient's own body. High-dose, intense chemotherapy or radiation therapy are used to treat cancer. Your stem cells and immune system may be harmed as a result of this treatment. That's why doctors remove or save your stem cells from your blood or bone marrow before commencing cancer treatment. After chemotherapy, the stem cells are restored to your body, restoring your immune system as well as your body's ability to make blood cells and fight cancer. This treatment is also known as autologous bone marrow transplantation or stem cell rescue.</li>
           <li className='heading-li'>Allogenic Bone Marrow Transplant: The term "allogenic" means "different." Another individual, referred to as a donor, is used to harvest stem cells. In most circumstances, the donor's DNA must at least partially match yours. Special tests are used to assess if a donor is a good match for you. A brother or sister is most likely to be a good match. Parents, children, and other relatives might make a suitable match on occasion. Allogenic BMT is categorised into three kinds based on the donor's relationship and tissue compatibility.</li>
           <li className='heading-li'>Transplantation of umbilical cord blood: This is an allogeneic transplant. Stem cells are removed from a new born baby's umbilical cord shortly after delivery. The stem cells are frozen and stored until they are needed for a transplant. Perfect matching isn't required because umbilical cord blood cells are so young. Due to the smaller quantity of stem cells in the blood, blood counts take substantially longer to restore.</li>
            <br></br><br></br>
            <h2 className='heading2'>Risk Factors For Bone Marrow Transplant</h2>
            <div className='separator'></div>
            <p>The risk factors for Bone Marrow Transplant are determined by a variety of factors. It could be your age, overall health, or the ailment for which you're being treated, for example.</p>
            <p>The type of transplant and how well the donor marrow matches the recipient marrow are also factors that influence the outcome. Another key factor is how a bone marrow transplant is carried out. The amount of radiation or chemotherapy you receive prior to your bone marrow transplant, as well as the dose you receive, affects the outcome of your transplant.</p>
            <p>The following are minor issues that may occur as a result of a bone marrow transplant:</p>
            <li className='heading-li'>Taste impairment</li>
            <li className='heading-li'>A persistent headache</li>
            <li className='heading-li'>Chances of getting a high fever</li>
            <li className='heading-li'>Nausea</li>
            <li className='heading-li'>Your blood pressure is dropping</li>
            <li className='heading-li'>Hives</li>
            <li className='heading-li'>Breathing difficulties</li>
            <li className='heading-li'>Chills</li>
            <br></br><br></br>
            <h2 className='heading2'>Support And Care</h2>
            <div className='separator'></div>
            <p>Following a bone marrow transplant, doctors and nurses will closely monitor the patients' recovery. Patients may require the following:</p>
            <li className='heading-li'>Blood transfusions are used to increase the number of red blood cells in the body.</li>
            <li className='heading-li'>Drugs to reduce immunity and help avoid GVHD, as well as medications to help with any side effects that may occur.</li>
            <li className='heading-li'>Lab tests</li>
            <li className='heading-li'>Medicine to fight infections</li>
        </div>


       



       
       {/* <input
          className="oncology-input"
          name="tabs"
          type="radio"
          id="tab-6"
          checked={activeTab === 'tab-6'}
          onChange={() => handleTabClick('tab-6')}
        />
        <label className="oncology-label" htmlFor="tab-6">Prostate Cancer</label>
        <div className="oncology-panel">
          <h1>Tekno Match</h1>
          <p>Tekno Match (Citrus ×clementina) is a hybrid between a mandarin orange and a sweet orange, so named in 1902. The exterior is a deep orange colour with a smooth, glossy appearance. Clementines can be separated into 7 to 14 segments. Similarly to tangerines, they tend to be easy to peel.</p>
        </div>
  */}
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default Hematology;
