// LocalCard.js

import React, { useState } from 'react';
import './LocalCard.css'; // Import your CSS file for styling
import grandeImage from '../../images/grande.jpeg'; // Import your image
import OmImage from '../../images/om.jpg';
import starImage from '../../images/star.jpg';
import norvicImage from '../../images/norvic.jpg';
import hamsImage from '../../images/hams.jpg';
import medicitiImage from '../../images/mediciti.jpg';



const LocalCard = () => {
  const [expanded, setExpanded] = useState(false);

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <>
     <div className="localcard-section">
        <div className="localcard-image">
          <img src={grandeImage} alt="Grande Hospital" />
        </div>
        <div className="localcard-content">
          <h2>Grande International Hospital</h2>
          <h4>Tokha Road, Kathmandu</h4>
          <p>
            Grande International Hospital was created with the vision to fill an existing void in
            the Nepali healthcare industry. It is dedicated to the seamless delivery of quality,
            patient-centric healthcare which means supplying everything a patient may need,
            from acute critical care to rehabilitation to transitional care to home healthcare
            services at an affordable cost. 
          </p>
          {expanded && (
            <p>Our goal is to establish in this country a culture of
            continuous improvement in healthcare not only by providing excellent healthcare
            services but also by conducting community health education seminars, outreach
            programs, and wellness programs in several parts of Nepal.

            The hospital is a 200 bedded, state of the art health care facility offering a wide
            range of medical, surgical and diagnostic services. The hospital has special features
            like a Wellness Center to help our community stay healthy. With latest in technology
            and a network of qualified physicians and staff, Grande International Hospital is
            your destination for the best healthcare services the country has to offer.</p>
          )}
          {expanded ? (
            <button className="toggle-button" onClick={handleToggleExpand}>
              Show Less
            </button>
          ) : (
            <button className="toggle-button" onClick={handleToggleExpand}>
              Show More
            </button>
          )}
        </div>
      </div>
   <div className="localcard-section">
      <div className="localcard-image">
        <img src={medicitiImage} alt="About Us" />
      </div>
      <div className="localcard-content">
        <h2>Nepal Mediciti Hospital</h2>
        <h4>Nakhkhu Patan, Karyabinayak </h4>
        <p>
          Nepal Mediciti Hospital stands as a beacon of excellence, renowned for its cutting-
          edge technology and commitment to providing world-class healthcare services. As
          the largest private hospital in Nepal, it proudly offers super-specialty treatment
          services, setting the standard for medical care in the region.

          Boasting an exceptional team of medical experts spanning various disciplines,
          Nepal Mediciti Hospital promises unparalleled treatments to its patients. Spread
          across an expansive 15 acres of land, this 700+ bedded facility is equipped to cater
          to a diverse range of medical needs with precision and expertise.

        </p>
        { expanded && (
          <p> We have around 100 Consultants of different specialties and 500 plus dedicated staff which
          always drives this hospital towards quality care. Our investment in state of the art technologies
          like CT Scan, MRI, Digital Mammography, Digital Endoscopy Polysomnography, Laparoscopic
          Surgery has us in providing better diagnosis and curative services to our patients. We are ISO
          9001:2015 Certified hospital promising quality care at every moment.<br></br><br></br>
          This hospital is the pioneer in establishing I.V.F. centre and caters patients from throughout the
          world. Also, this is a referral center for Gynaecology and Obstetrics services, Haemodialysis,
          Oncology, Critical Care and Complicated Surgeries. It assures to fulfill the need of patients and
          always dedicated towards providing compassionate care.</p>
        )}
         {expanded ? (
            <button className="toggle-button" onClick={handleToggleExpand}>
              Show Less
            </button>
          ) : (
            <button className="toggle-button" onClick={handleToggleExpand}>
              Show More
            </button>
          )}
      </div>
    </div>
    <div className="localcard-section">
      <div className="localcard-image">
        <img src={starImage} alt="About Us" />
      </div>
      <div className="localcard-content">
        <h2>Star Hospital</h2>
        <h4> Patan, Lalitpur</h4>
        <p>
        Star Hospital was established in 2007 as a 50-bedded, multispecialty hospital. On May 30, 2017,
        the hospital shifted to its own premises in Sanepa-2, Lalitpur. It is the first hospital to meet the
        standards set by the Ministry of Health and Population of Nepal. Our 100-bedded hospital has 22
        specialty centres and features state-of-the-art modular operation theatres. Our long-term vision is
        to establish a well-equipped multi-specialty hospital.
        </p>
        {expanded ? (
            <button className="toggle-button" onClick={handleToggleExpand}>
              Show Less
            </button>
          ) : (
            <button className="toggle-button" onClick={handleToggleExpand}>
              Show More
            </button>
          )}
      </div>
    </div>

   <div className="localcard-section">
      <div className="localcard-image">
        <img src={norvicImage} alt="About Us" />
      </div>
      <div className="localcard-content">
        <h2>Norvic International Hospital</h2>
        <h4>Thapathali, Kathmandu</h4>
        <p>
        Norvic International to be first hospital in Private sector in the country to establish private
        cardiac center to provide from very basic ECG, Echocardiography, ABP, Holter, Coronary
        Angiogram to complex Angioplasty of Coronary, carotid and renal Artery. CABG and Valve
        replacement surgeries. At Norvic we started first Mini PCNL stone removal surgery in Nepal.
        </p>

        {expanded ? (
            <button className="toggle-button" onClick={handleToggleExpand}>
              Show Less
            </button>
          ) : (
            <button className="toggle-button" onClick={handleToggleExpand}>
              Show More
            </button>
          )}
      </div>
    </div>

   <div className="localcard-section">
      <div className="localcard-image">
        <img src={hamsImage} alt="About Us" />
      </div>
      <div className="localcard-content">
        <h2>Hospital for Advanced Medicine & Surgery (HAMS) Hospital</h2>
        <h4>Mandikhatar Road, Kathmandu </h4>
        <p>
        Hospital for Advanced Medicine & Surgery (HAMS) is a multi-disciplinary tertiary
        care hospital situated in Dhumbarahi, Kathmandu. With over 25 years of
        experience and expertise, we have been providing quality and affordable
        healthcare to the community. 
        </p>
        {expanded && (
          <p>We are proud of our highly-experienced clinicians,
          technicians & administrators, backed by state-of-the-art technology and
          dependable infrastructure. Our hospital is fostered by highly-trained and caring
          nurses who strive to give you the best patient care and experience the town has
          to offer.</p>
        )}
        {expanded ? (
            <button className="toggle-button" onClick={handleToggleExpand}>
              Show Less
            </button>
          ) : (
            <button className="toggle-button" onClick={handleToggleExpand}>
              Show More
            </button>
          )}
      </div>
    </div>

 
    <div className="localcard-section">
      <div className="localcard-image">
        <img src={OmImage} alt="About Us" />
      </div>
      <div className="localcard-content">
        <h2>Om Hospital & Research Centre</h2>
        <h4>Chabahil, Kathmandu</h4>
        <p>
        Our Journey was started more than 33 years back in 1990 A.D (2046 B.S) by the name of Om
        Nursing Home aiming to provide world class, holistic and affordable healthcare. Initially, Om
        Nursing Home was operated with 8 beds and 3 doctors.

        It was in 1996 A.D (2052 B.S) Om Nursing Home was registered as Om Hospital & Research
        Centre (P) Ltd. at Kamalpokhari, Kathmandu, which was never possible without dedicated
        professionals and the trust that the community had shown towards the hospital.

        </p>
        { expanded && (
          <p> The role of this hospital is to provide all types of diagnostic, preventive and curative services
          under one roof at affordable price. This hospital also focuses to provide training and knowledge
          to healthcare professionals and to provide research information to the medical world. Currently
          OMHRC has building construction covering 1,19,313 sq ft. with total land area of approximately
          87,600 sq ft.
  
          We have around 100 Consultants of different specialties and 500 plus dedicated staff which
          always drives this hospital towards quality care. Our investment in state of the art technologies
          like CT Scan, MRI, Digital Mammography, Digital Endoscopy Polysomnography, Laparoscopic
          Surgery has us in providing better diagnosis and curative services to our patients. We are ISO
          9001:2015 Certified hospital promising quality care at every moment.
      </p>
        )}
         {expanded ? (
            <button className="toggle-button" onClick={handleToggleExpand}>
              Show Less
            </button>
          ) : (
            <button className="toggle-button" onClick={handleToggleExpand}>
              Show More
            </button>
          )}
      </div>
  </div> 
    </>
    
  );
};

export default LocalCard;
