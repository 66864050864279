const people = [
    {
        id: 1,
        quote:
        "I've been to several hospitals affiliated with ABC Healthcare Solutions, and I must say, the facilities they provide truly set a high standard in healthcare. From well-maintained facilities to efficient medical staff, every aspect of their service is geared towards patient comfort and satisfaction. I'm grateful for their dedication to providing top-notch healthcare experiences.",
        name: "- Rabindra Sapkota",
        title: ""
    },
  
    {
      id: 2,
      quote:
      "ABC Healthcare Solutions Air Ambulance service provided me with exceptional care and professionalism during my medical transport. From the moment I contacted them, their professionalism was evident in their efficient document processing. The medical team onboard was exceptional, providing expert care with well-equipped medical instruments. I felt reassured and comfortable throughout the journey, knowing I was in capable hands. I highly recommend ABC Healthcare Solutions for their top-notch service and dedication to patient care.",
      name: "- Anil Maharjan",
      title: ""
  },
  
  {
    id: 3,
    quote:
    " ABC Healthcare Solutions facilitated my kidney transplant in India with utmost professionalism and care. Their team ensured seamless coordination, top-notch medical expertise, and personalized attention throughout the process. Thanks to them, I received world-class treatment and am now on the road to recovery. Highly recommend their services for anyone seeking medical care abroad.”",
    name: "- Nabin Gurung",
    title: ""
  },
  
  {
    id: 4,
    quote: "Choosing a hospital equipped with ABC Healthcare Solutions facilities was the best decision I made for my healthcare needs. The level of comfort and convenience they provide to patients is unmatched. From prompt services to hygienic surroundings, every aspect of my hospital stay exceeded my expectations. Thank you, ABC Healthcare Solutions, for prioritizing patient well-being.",
    name: "- Dinesh Shrestha",
    title: ""
  },
  
  {
    id: 5,
    quote: "ABC Healthcare Solutions made accessing general OPD services incredibly easy with their streamlined document process. Their efficiency and attention to detail ensured a hassle-free experience from start to finish. I'm grateful for their assistance in navigating the healthcare system, making my visit to the doctor stress-free and efficient",
    name: "- Ram Chandra Thapa",
    title: ""
  },
  
  
  
  
    
   
  
  ]
  export default people
  
  