import React, { useEffect, useState } from 'react';
import './TourismService.css';
import service from '../../images/service.PNG'

function TourismService() {
  const [animated, setAnimated] = useState(false);

  useEffect(() => {
    // Adding animation class after component mounts
    setAnimated(true);
  }, []);

  return (
    <div className={`tourism-service-container ${animated ? 'animated' : ''}`}>
      <div className="tourism-service-text">
        <h2>Why ABC HealthCare Solutions?</h2>
        <p>ABC HealthCare Solutions is just not another medical tourism company in Nepal that connects you with the hospitals in Nepal. The purpose is to offer you cost-effective, technology-based, safe and secure, and most importantly reliable medical services to people across the world. Medicaltours.care follows the highest degree of ethics, confidentiality, and international norms inpatient care.<br></br><br></br>

        Understanding the importance of well-being and good health, we commit to provide world-class Medical Care at affordable prices with the multi-super specialty hospitals across the country. Our team arranges hassle-free travel logistics, visa, transportation, surgery, hospitalization, and stay in Nepal. We assist you right from the minute you decide to come to India through free medical consultancy, medical recovery, personal assistance, tours, and travel.</p>
      </div>
      <img src={service} alt="Placeholder Image" className="tourism-service-image" />
    </div>
  );
}

export default TourismService;
