import React, { useState } from 'react';
import './Oncology.css';
import Footer from '../../../elements/Footer/Footer';
import OrganImage from '../OrganImage';

const Cosmetic = () => {
  const [activeTab, setActiveTab] = useState('tab-1');

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <>
    <OrganImage/>
    <div className='oncology-container'>
      <div className="oncology-tabs">
        <input
          className="oncology-input"
          name="tabs"
          type="radio"
          id="tab-1"
          checked={activeTab === 'tab-1'}
          onChange={() => handleTabClick('tab-1')}
        />
        <label className="oncology-label" htmlFor="tab-1">Buttock Enhancement</label>
        <div className="oncology-panel">
          <h1>Benefits Of Butt Lift Surgery</h1>
          <p>Did you know that seeing a woman's big butt can stimulate reward centres in men's brains that are normally triggered by drugs, alcohol, or delicious food? According to studies, males like women with huge buttocks and small waists. Butt lift surgery has recently become trendy among ladies. Many ladies are undergoing butt lift surgery without any kind of hesitation or dread.</p>
          <p>Bladder cancer that starts in cells of the bladder's inner lining is the most prevalent form (transitional cell carcinoma). Squamous cell carcinoma, which starts in the thin, flat cells lining the inside of the bladder, and adenocarcinoma, which starts in the cells that secrete mucus, are the other forms.</p>
        </div>

        <input
          className="oncology-input"
          name="tabs"
          type="radio"
          id="tab-2"
          checked={activeTab === 'tab-2'}
          onChange={() => handleTabClick('tab-2')}
        />
        <label className="oncology-label" htmlFor="tab-2">Breast Augmentation</label>
        <div className="oncology-panel">
          <h1>Idntheme</h1>
          <p>Idntheme (Citrus tangerina) is an orange-colored citrus fruit that is closely related to, or possibly a type of, mandarin orange (Citrus reticulata).</p>
          <p>The name was first used for fruit coming from Tangier, Morocco, described as a mandarin variety. Under the Tanaka classNameification system, Citrus tangerina is considered a separate species.</p>
        </div>

        <input
          className="oncology-input"
          name="tabs"
          type="radio"
          id="tab-3"
          checked={activeTab === 'tab-3'}
          onChange={() => handleTabClick('tab-3')}
        />
        <label className="oncology-label" htmlFor="tab-3">Plastic Surgery</label>
        <div className="oncology-panel">
          <h1>Idntheme</h1>
          <p>Idntheme (Citrus tangerina) is an orange-colored citrus fruit that is closely related to, or possibly a type of, mandarin orange (Citrus reticulata).</p>
          <p>The name was first used for fruit coming from Tangier, Morocco, described as a mandarin variety. Under the Tanaka classNameification system, Citrus tangerina is considered a separate species.</p>
        </div>
       </div>
    </div>
    <Footer/>
    </>
  );
};

export default Cosmetic;
