import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressBook, faEnvelope, faLocation, faMobile, faPhone, faPhoneAlt, faSquarePhone, faTruckMoving } from '@fortawesome/free-solid-svg-icons';
import logo from '../../images/abc.png'; // Replace with the actual path to your logo image
import './Footer.css'

function Footer() {

  return (
    <footer className="footer parallax-footer">
      <div className="footer-content">
        <div className="footer-column">
          <h3>About Us</h3>
          <img src={logo} height={90} alt="Logo" />
          <p>"ABC Healthcare Solution is a medical assistance company that gives proper guidance to a patient from the first consultation through the entire hospitalization process."</p>
        </div>
        <div className="footer-column">
          <h3>Contact</h3>
          <p><FontAwesomeIcon icon={faEnvelope} className='icons-footer' /> Email: <a href="https://mail.google.com/mail/u/0/#inbox">info@abchealthcaresolutions.com</a></p>
          <p><FontAwesomeIcon icon={faSquarePhone} className='icons-footer'  /> Landline: <a href="#">01-4517040</a></p>
          <p><i className="fa-brands fa-whatsapp"></i> Whatsapp Number:  <a href="#">+977-9851206644</a>, <a href="#">+977-9869182611</a></p>
          <p><FontAwesomeIcon icon={faLocation} className='icons-footer'/>Address: <a>Naxal, Bhatbhateni-4, Kathmandu, Nepal</a></p>
        </div>
        <div className="footer-column"> 
          <h3>Follow Us</h3>
          <div className="social-icons">
            <a href="https://www.facebook.com/abchealthcaresolution/"><i className="fab fa-facebook"></i></a> 
            <a href="#"><i className="fab fa-instagram"></i></a>
            <a href=''><i className='fab fa-linkedin'></i></a>
          </div>
        </div>
      </div>
      <div className="footer-separator"></div>
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} ABC Healthcare Solution | All Rights Reserved.</p>
        <p>Website Designed & Maintained by - Samrat</p>

      </div>
    </footer>
  );
}

export default Footer;
