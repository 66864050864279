import React, { useRef } from 'react';
import './TourismHero.css';
import image from '../../images/doctor.jpg'; // Import your image
import { useInView } from 'react-intersection-observer';

const TourismHero = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5, // Adjust as needed
  });

  return (
    <div className={`tourism-hero-container ${inView ? 'visible' : ''}`} ref={ref}>
      <div className="tourism-image-content">
        <img src={image} alt="Your Image" />
      </div>
      <div className="tourism-text-content">
        <h2 className="tourism-heading">Medical Tourism in Nepal</h2>
        <p className="tourism-paragraph">
          Congratulations!! You've made a great start by deciding Nepal to be the destination for your medical treatment. You won't repent it. Cost is not the parameter you should search the medical facilities with are not just a facilitator to connect you with hospitals here. You can reach them yourself by searching for the best hospitals on Google.com. We are here to provide you a service that you can rely on. <br /><br /> At ABC Healthcare, we are a team of skilled, experienced professionals, focusing on providing quality healthcare services in Nepal. We are associated with the most famous and prestigious hospitals, clinics, specialists, and doctors. We have selected the hospitals that are well-equipped with the finest infrastructure, modern equipment, trained support staff, and international standards of hygiene.
        </p>
      </div>
    </div>
  );
};

export default TourismHero;
