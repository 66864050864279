import React from 'react'
import localImage from '../../images/hospitalback-min.jpg'
import './LocalImage.css'

const LocalImage = () => {
  return (
    <>
     <div className="local-image-section  animated-section">
      <img src={localImage} alt="Example Image" className='local-image' />
    </div>
      
    </>
  )
}

export default LocalImage
