import React from 'react'
import './TreatmentText.css'

const OrganText = () => {
  return (
    <>
    <div className='treatment-text-section'>
        <h2>Organ Transplants</h2>
    </div>
    </>
  )
}

export default OrganText
