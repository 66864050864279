import React, { useState } from 'react';
import './Oncology.css';
import Footer from '../../../elements/Footer/Footer';
import OrganImage from '../OrganImage';

const Gyno = () => {
  const [activeTab, setActiveTab] = useState('tab-1');

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <>
    <OrganImage/>
    <div className='oncology-container'>
      <div className="oncology-tabs">
        <input
          className="oncology-input"
          name="tabs"
          type="radio"
          id="tab-1"
          checked={activeTab === 'tab-1'}
          onChange={() => handleTabClick('tab-1')}
        />
        <label className="oncology-label" htmlFor="tab-1">Infertility</label>
        <div className="oncology-panel">
          <h1>What Is Infertility?</h1>
          <div className='separator'></div>
          <p>Infertility is described as "a disorder of the reproductive system characterized by the failure to obtain a clinical pregnancy after 12 months or more of frequent unprotected sexual intercourse. In other terms, infertility is described as a woman's inability to become pregnant after attempting for at least a year but failing to do so despite regular intercourse. Primary infertility and secondary infertility are the two forms of infertility. Infertility is a couple that has never conceived is referred to as primary infertility. Secondary infertility occurs when a couple has previously been pregnant but is unable to conceive. Infertility affects up to 15% of reproductive-aged couples worldwide.</p>
          <p>InFertility treatment refers to the medication followed in order to help those having problems bearing a child. So, artificial medical assistance is taken to aid them in the process. Since Fertility Treatment is used to cure conditions of infertility in patients, it is also referred to as 'Infertility Treatment' (Fertility Treatment) or the treatment to treat infertility. This kind of artificial medical assistance to aid reproductive functions is increasingly becoming popular day by day. Infertility refers to the inability to naturally conceive a child. It is a problem where a couple faces problems in conceiving, after a year of regular sexual intercourse without using any birth control methods. This problem may be in either man or woman, or both. It also refers to a condition of a woman when she is unable to carry a pregnancy to its full term.</p>
          <br></br><br></br>
          <h2 className='heading2'>Causes Of Infertility</h2>
          <div className='separator'></div>
          <p>In a normal reproduction process proper interaction between the female and male reproductive system is required. Following are the causes of infertility (both men and women):</p>
           <li className='heading-li'>Sperm causes.</li>
          <li className='heading-li'>Ovulation problems.</li>
          <li className='heading-li'>Tubal causes.</li>
          <li className='heading-li'>Age-related factors.</li>
          <li className='heading-li'>Unexplained infertility.</li>
          <br></br><br></br>
          <h2 className='heading2'>How To Manage Infertility</h2>
          <div className='separator'></div>
           <p>The age at which you should seek professional help for infertility management is determined by your age. Women under the age of 30 are advised to try to conceive on their own for a year before considering therapeutic options. Both partners must undergo a full medical history and physical examination as part of infertility testing. There are blood and imaging tests that must be completed. This can include things like:</p>
          <li className='heading-li'>Hormone levels, such as progesterone and follicle-stimulating hormone, are measured in the blood.</li>
          <li className='heading-li'>Taking your body temperature first thing in the morning to see if your ovaries are producing eggs, is a good idea.</li>
          <li className='heading-li'>Challenge test with FSH (Follicle Stimulating Hormone) and Clomid.</li>
          <li className='heading-li'>Hysterosalpingography is a type of hysterosalpingography (HSG).</li>
          <li className='heading-li'>Ultrasound of the pelvis.</li>
          <li className='heading-li'>Evaluation with laparoscopy and hysteroscopy.</li>
          <li className='heading-li'>Urine test for luteinizing hormone (ovulation prediction).</li>
        </div>


        <input
          className="oncology-input"
          name="tabs"
          type="radio"
          id="tab-2"
          checked={activeTab === 'tab-2'}
          onChange={() => handleTabClick('tab-2')}
        />
        <label className="oncology-label" htmlFor="tab-2">Assisted Replacement</label>
        <div className="oncology-panel">
          <h1>What Do You Understand About Laser Hatching?</h1>
          <div className='separator'></div>
          <p>Laser Hatching is a technique to remove the zona pellucida surrounding the embryos or blastocysts, which can be easily done by laser. The laser acts on the zona cells but leaves the embryo intact and undamaged. The zona cells are destroyed by fusing (like dissolving sugar in water), thereby allowing fertilization without needing sperm to penetrate it. This is useful where sperm has been used from frozen stored spermatozoa and hence freezing of sperm and freezing of embryos may not be available.</p>
           <p>The viability of the embryos contains a high correlation to embryo transfer success rates. When the embryo is transferred into the uterus, it still needs time to get past the zona pellucida layer and burrow into the endometrium lining. This can take several days, and if the embryos are placed at an inappropriate time of their development (too early or too late) or inadequate conditions exist in which to implant, then the pregnancy rate may be low.</p>
            
            <br></br><br></br>
            <h2 className='heading2'>Surgical Sperm Retrieval</h2>
            <div className='separator'></div>
            <p>Surgical Sperm Retrieval is used in patients who face ejaculatory disorders or no sperm in the ejaculate.</p>
            <p>In case, two or more good quality embryos are produced during the cycle, but they are not transferred, then they can be frozen for future use. They are frozen at extremely low temperature of around -180Â°C, so that they do not get deteriorate with time. In this procedure, the woman has to take medicine for preparing her womb to receive these embryos. With the help of an ultrasound the lining of the uterus is determined that whether it is ready for the transfer or not.</p>
            <br></br><br></br>
            <h2 className='heading2'>Benefits Of Assisted Reproduction (ART )Treatment</h2>
            <div className='separator'></div>
            <p>Assisted Reproductive Technology will benefit many previously infertile couples. It is extremely important that a couple facing infertility problem should come forward to discuss their problem. We can arrange for Free of cost opinions from various super speciality hospitals before doctor's consultations of your query from the experts of our connected hospitals</p>
           
        </div>

        <input
          className="oncology-input"
          name="tabs"
          type="radio"
          id="tab-3"
          checked={activeTab === 'tab-3'}
          onChange={() => handleTabClick('tab-3')}
        />
        <label className="oncology-label" htmlFor="tab-3">Artificial Insemination</label>
        <div className="oncology-panel">
          <h1>What Is IUI ?</h1>
          <div className='separator'></div>
          <p> Intrauterine insemination IUI, also called artificial insemination is a basic step in the direct of fertility treatments. The treatment can be done using partner's (husband) sperm or sperm from a donor.</p>
          <p>This low tech process can be done in two ways: natural and with ovulation induction. In the first manner, the body is monitored for ovulation and when the LH surge is higher, one should contact the fertility clinic for further instructions. They will inform you when and how to collect a sperm sample.</p>
            <br></br><br></br>
          <h2 className='heading2'>IUI Procedure</h2>
          <div className='separator'></div>
          <p>It is done by threading a very thin flexible catheter through the cervix and injecting washed sperm straight into the uterus, taking just a few minutes. If the cervix is hard to reach then a tenaculum is used to hold it, making the process a bit uncomfortable.</p>
            <br></br><br></br>
          <h2 className='heading2'>When Is Sperm Collected For IUI ?</h2>
          <div className='separator'></div>
          <p>The sperm is usually collected through ejaculation into a sterile collection cup or in collection condoms. Most of clinics want the semen within a half hour of ejaculation, so if one lives close then the sperms are collected at home but if not, one has to go to the clinic and do it in a private setting. After this washing is done and the sperms are used for IUI.</p>
            <br></br><br></br>
          <h2 className='heading2'>Benefits, Success Rate And Support For IUI Treatment</h2>
          <div className='separator'></div>
          <p>The success rates IUI falls between 5 -15 percent per cycle, on the woman's age (up to 35 years), sperm count of the male partner and the female's tubes that should be healthy. Generally three cycles of IUI are tried, if they fail then advanced methods are recommended.</p>
          <p>IUI is a simple and inexpensive form of therapy, thus it should be tried first, then going on to more expensive options. In comparison to other countries, India offers treatment at affordable rates.</p>
        </div>

        <input
          className="oncology-input"
          name="tabs"
          type="radio"
          id="tab-4"
          checked={activeTab === 'tab-4'}
          onChange={() => handleTabClick('tab-4')}
        />
        <label className="oncology-label" htmlFor="tab-4">IVF Treatment</label>
        <div className="oncology-panel">
          <h1>What Is IVF?</h1>
          <div className='separator'></div>
          <p>In vitro fertilization (IVF) is a process where an egg is joined with sperm in vitro (“in glass”). The process includes observing and invigorating a lady’s ovulatory cycle, eliminating an ovum or ova (egg or eggs) from her ovaries and allowing sperm to prepare them in a culture medium in a laboratory. After the prepared egg (zygote) goes through embryo culture for 2-6 days, it is embedded in an uterus, fully intent on laying out a successful pregnancy.</p>
            <br></br><br></br>
          <h2 className='heading2'>Why Is IVF Done?</h2>
          <div className='separator'></div>
          <p>At times, IVF is presented as an essential treatment for barrenness in ladies over the age of 40.
            In vitro preparation (IVF) is a treatment for infertility or hereditary issues. If IVF is performed to treat infertility, you and your partner could possibly attempt less-obtrusive treatment choices prior to endeavoring IVF, including infertility medications to increase creation of eggs or intrauterine insemination a strategy where sperm are placed directly in the uterus close to the hour of ovulation.</p>
        <br></br><br></br>
        <h2 className='heading2'>Move The Embryo Into The Uterus</h2>
        <div className='separator'></div>
        <p>When your eggs have been gathered you will get one more medicine. This one is intended to prepare the coating of your uterus to get the undeveloped organisms that will be moved once again into you.</p>
        <p>Around three to five days after preparation, your PCP will put the undeveloped organisms in your uterus utilizing a catheter. Like advance number three, this piece of IVF is acted in your doctor office while you are alert.</p>
        <p>Various embryos are moved once more into you with the expectation that somewhere around one will embed itself in the covering of your uterus and start to create. In some cases, more than one incipient organism winds up embedding, which is the reason products are normal in ladies who use IVF.</p>
        <br></br><br></br>
        <h2 className='heading2'>IVF Results</h2>
        <div className='separator'></div>
        <p>Around 12 days to about fourteen days after egg recovery, your doctor will test an example of your blood to detect whether you are pregnant or not.
            If in case you’re pregnant, your doctor will allude you to an obstetrician or other pregnancy expert for pre-birth care.

            In the other hand there is some sort of chance you may not be pregnant; you will quit taking progesterone and logically get your period soon. In case that you do not get your period or you have strange dying, contact your doctor. If you are interested on endeavoring one more pattern of in vitro preparation (IVF), your doctor could propose steps you can take to work on your risks of getting pregnant through IVF.
        </p>
        <p>The possibilities of bringing a healthy child in the wake of utilizing IVF depends upon different variables, including:

        Maternal age: The age of mother may be effects the health of new born baby if you’re young then the chances of pregnancy is higher . Women age, 41 and more matured are frequently guided to consider utilizing donor eggs during IVF to expand the chance of success.

        Embryo status: Transfer of embryos that are more developed is associated with higher pregnancy rates compared with less-developed embryos (day two or three). However, not all embryos survive the development process. Talk with your doctor or other care provider about your specific situation.

        Reproductive history: Ladies who have recently conceived a child are bound to have  the option to get pregnant utilizing IVF than are ladies who have never conceived a child. Achievement rates are lower for ladies who have recently utilized IVF on numerous occasions but did not get pregnant</p>
     
        </div>

         <input
          className="oncology-input"
          name="tabs"
          type="radio"
          id="tab-5"
          checked={activeTab === 'tab-5'}
          onChange={() => handleTabClick('tab-5')}
        />
        <label className="oncology-label" htmlFor="tab-5">Assisted Hatching</label>
        <div className="oncology-panel">
          <h1>What Do You Understand About Laser Hatching?</h1>
          <div className='separator'></div>
          <p>Laser Hatching is a technique to remove the zona pellucida surrounding the embryos or blastocysts, which can be easily done by laser. The laser acts on the zona cells but leaves the embryo intact and undamaged. The zona cells are destroyed by fusing (like dissolving sugar in water), thereby allowing fertilization without needing sperm to penetrate it. This is useful where sperm has been used from frozen stored spermatozoa and hence freezing of sperm and freezing of embryos may not be available.</p>
          <p>The viability of the embryos contains a high correlation to embryo transfer success rates. When the embryo is transferred into the uterus, it still needs time to get past the zona pellucida layer and burrow into the endometrium lining. This can take several days, and if the embryos are placed at an inappropriate time of their development (too early or too late) or inadequate conditions exist in which to implant, then the pregnancy rate may be low.</p>
          <br></br><br></br>
          <h2 className='heading2'>Surgical Sperm Retrieval</h2>
          <div className='separator'></div>
          <li className='heading-li'>Percutaneous Epididymal Sperm Aspiration (PESA)</li>
          <li className='heading-li'>Testicular Sperm Aspiration (TESA)</li>
          <li className='heading-li'>Testicular Sperm Extraction (TESE)</li>
          <li className='heading-li'>Frozen Embryo Replacement Cycle (FERC)</li>
            <br></br><br></br>
           
            <h2 className='heading2'>Assisted Reproduction (ART) Procedures</h2>
          <div className='separator'></div>
          <p>Intrauterine Insemination, IUI is collection of semen that are emitted during procedures other than intercourse or masturbation). The semen is directly transferred into the uterus with the help of a catheter. This treatment can be done during a natural cycle but in order to improve pregnancy rate, it is important that fertility enhancing drugs are used.</p>
          <p>In-vitro fertilization, IVF, also known as test tube baby, is the most commonly used treatment all around the world. It involves fertilization of an ovum (egg) that is exposed to around 100,000 or less sperms in a test tube for fertilization outside the body. After this, the embryo is transferred into the uterus of the female.</p>
        </div>
       </div>
    </div>
    <Footer/>
    </>
  );
};

export default Gyno;
