import React from 'react';
import './ProcedureImage.css'
import procedureImage from '../../images/caringdoctor.jpg'; // Import your image here

const ProcedureImage = () => {
  return (
    <div className="procedure-image-section">
      <img src={procedureImage} alt="Example Image" className='procedure-image' />
    </div>
  );
};

export default ProcedureImage;
