import React, { useState } from 'react';
import './Oncology.css';
import OrganImage from '../OrganImage';
import Footer from '../../../elements/Footer/Footer';

const Pediatric = () => {
  const [activeTab, setActiveTab] = useState('tab-1');

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <>
    <OrganImage/>
    <div className='oncology-container'>
      <div className="oncology-tabs">
        <input
          className="oncology-input"
          name="tabs"
          type="radio"
          id="tab-1"
          checked={activeTab === 'tab-1'}
          onChange={() => handleTabClick('tab-1')}
        />
        <label className="oncology-label" htmlFor="tab-1">Cardiac Surgery</label>
        <div className="oncology-panel">
          <h1>Pediatric Cardiac Surgery</h1>
          <div className="separator"></div>
          <p>Pediatric Cardiac Surgery often deals with heart problems in children, the cause of congenital heart diseases which are common heart ailments among new born children and involves deficiencies like structural defects, congenital arrythmias and cardiomyopathies, which result in different kinds of abnormalities related to the heart. Congenital heart disease is a cause of improper growth of the heart or blood vessels before birth.</p>
          <p>Sometimes heart surgery in children is required for repairing defects in the heart which a child might have born with (known as congenital heart defects) and heart diseases which he gets after birth, which require surgery. Pediatric Cardiac Surgery deals with operative procedures in the newborn and unborn children and youngsters suffering from cardiac dysfunctions, structural, functional and rhythm-related issues of the heart also.</p>
           

            <br></br><br></br>
          <h2 className='heading2'>Pediatric Cardiology Facilities</h2>
          <div className="separator"></div>
            <p>The Paediatric cardiology department of any hospital offers all non-invasive diagnostic and paediatric cardiac interventional services. The diagnostic modalities are inclusive of foetal echocardiography, transthoracic and transesophageal echocardiography, round the clock Holter, CT and MR angiography as well as diagnostic cardiac catheterisation. Cardiologists, cardiovascular surgeons, anesthesiologists and other specialists work closely together to care for newborn as well as unborn children suffering from cardio-vascular disorders who provide exceptional pre and post operative services.</p>
            <br></br><br></br>
           <h2 className='heading2'>Pediatric Cardiothoracic Surgery</h2>
           <div className="separator"></div>
           <p>There are a number of Pediatric Cardiology Surgeries such as balloon atrial septostomy, Valvuloplasty/Angioplasty, Patent Ductus Arteriosus, Ventricular Septal Defect and Atrial Septal Defect, Tetralogy of Fallot, Total Anomalous Pulmonary Venous Return, Double Outlet Right Ventricle etc. </p>
            <br></br><br></br>
           
        </div>


       



       
       {/* <input
          className="oncology-input"
          name="tabs"
          type="radio"
          id="tab-6"
          checked={activeTab === 'tab-6'}
          onChange={() => handleTabClick('tab-6')}
        />
        <label className="oncology-label" htmlFor="tab-6">Prostate Cancer</label>
        <div className="oncology-panel">
          <h1>Tekno Match</h1>
          <p>Tekno Match (Citrus ×clementina) is a hybrid between a mandarin orange and a sweet orange, so named in 1902. The exterior is a deep orange colour with a smooth, glossy appearance. Clementines can be separated into 7 to 14 segments. Similarly to tangerines, they tend to be easy to peel.</p>
        </div>
  */}
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default Pediatric;
