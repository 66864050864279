import React, { useState } from 'react';
import './Oncology.css';
import OrganImage from '../OrganImage';
import Footer from '../../../elements/Footer/Footer';

const Spine = () => {
  const [activeTab, setActiveTab] = useState('tab-1');

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <>
    <OrganImage/>
    <div className='oncology-container'>
      <div className="oncology-tabs">
        <input
          className="oncology-input"
          name="tabs"
          type="radio"
          id="tab-1"
          checked={activeTab === 'tab-1'}
          onChange={() => handleTabClick('tab-1')}
        />
        <label className="oncology-label" htmlFor="tab-1">Cervical Spine Surgery</label>
        <div className="oncology-panel">
          <h1>What Is Cervical Spine Surgery?</h1>
          <div className="separator"></div>
          <p>Cervical spine surgery is performed to remove herniated discs and fuse the vertebrae together, as well as other operations to remove tumors, treat infections or correct spinal deformities. Cervical spine surgery procedures are performed by a skilled surgeon using minimally invasive techniques that minimize scarring and recovery time. It is a type of surgery that takes place on the cervical (neck) area of your spine This part of your spine is called the cervix because it connects the head and upper body to the lower part of the body (the thoracic and lumbar areas). During cervical spine surgery, a doctor may repair or replace disks or vertebrae. Also, surgeons will fix fractures and repair bony injuries caused by trauma. Cervical spine surgery can be performed with an open approach or with robotic assistance.</p>
        
            <br></br><br></br>
          <h2 className='heading2'>What Are Cause Of Cervical Spine Surgery?</h2>
          <div className="separator"></div>
          <p>
            When a degenerative illness or deformity affects the cervical spine, surgery is required. The intervertebral disc contracts because of abnormalities or degenerative disease, causing the disc to break down. Disc herniation, also known as disc bulging, can occur because of the breakdown. A patient with degeneration disease has Numbness, stinging, weakness, and considerable discomfort in the arms and hands are the most typical symptoms of cervical spine herniation. If physical therapy and medicines do not help your symptoms, you will be referred for Cervical Spine Surgery. The operation can help to relieve anxiety in the spinal cord.</p>
            <br></br><br></br>
           <h2 className='heading2'>What Happens During Corneal Transplant Surgery?</h2>
           <div className="separator"></div>
           <p>In most cases, the surgery is done on an outpatient basis. During the surgery, the patient will be given a sedative to help him or she relax. After that, he or she will be getting anesthesia to make the eye numb and after that, the eye will be washed and cleansed. Now, the eye is still, and the doctors proceed with the procedure the surgery. In this procedure, the doctors make highly precise cuts on the cornea for its removal. The whole process is done under a microscope. An object known as a trephine which looks like a circular cookie cutter instrument is used to remove the center of the diseased or damaged cornea. Throughout the process, the overall surgery is done on the central portion of the cornea. After that, the surgeon excises an appropriate circular disc of the patient’s cornea and replaces it with a similarly sized disc of the donor’s cornea, which is then sutured into place.</p>
            <br></br><br></br>
            <h2 className='heading2'>Symptoms That Your Cervical Spine Might Be Affected</h2>
            <div className='separator'></div>
            <li className='heading-li'>Agony.</li>
            <li className='heading-li'>Head or neck injury.</li>
            <li className='heading-li'>Arms and legs are weak or numb.</li>
            <li className='heading-li'>Injury to the neck causes neck pain.</li>
            <li className='heading-li'>Neck pain accompanied by a fever or a headache.</li>

          
            <br></br><br></br>
            <h2 className='heading2'>How To Prepare For Cervical Spine Surgery?</h2>
            <div className='separator'></div>
            <li className='heading-li'>Before having surgery, keep your health in check.</li>
            <li className='heading-li'>Organize your home so that items you will need after your surgery are readily available.</li>
            <li className='heading-li'>Two weeks before to cervical surgery, quit smoking. Smoking slows the healing of bone grafts.</li>
            <li className='heading-li'>It means keeping your phone, shoes, and toiletries in easy-to-reach locations that do not require bending.</li>
            <li className='heading-li'>Make a blood donation. You may be obliged to donate blood or have donations made in your name, depending on the surgery.</li>
            <li className='heading-li'>Get your hospital supplies ready. Comfortable apparel, such as pyjamas or sweatpants, as well as a robe and non-slip slippers, should be included. Get your essentials together, including a comb, toothpaste, and a toothbrush.</li>
        </div>


       



       
       {/* <input
          className="oncology-input"
          name="tabs"
          type="radio"
          id="tab-6"
          checked={activeTab === 'tab-6'}
          onChange={() => handleTabClick('tab-6')}
        />
        <label className="oncology-label" htmlFor="tab-6">Prostate Cancer</label>
        <div className="oncology-panel">
          <h1>Tekno Match</h1>
          <p>Tekno Match (Citrus ×clementina) is a hybrid between a mandarin orange and a sweet orange, so named in 1902. The exterior is a deep orange colour with a smooth, glossy appearance. Clementines can be separated into 7 to 14 segments. Similarly to tangerines, they tend to be easy to peel.</p>
        </div>
  */}
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default Spine;
