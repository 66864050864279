import React, { useState } from 'react';
import './Oncology.css';
import Footer from '../../../elements/Footer/Footer';
import OrganImage from '../OrganImage';

const Cardio = () => {
  const [activeTab, setActiveTab] = useState('tab-1');

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <>
    <OrganImage/>
    <div className='oncology-container'>
      <div className="oncology-tabs">
        <input
          className="oncology-input"
          name="tabs"
          type="radio"
          id="tab-1"
          checked={activeTab === 'tab-1'}
          onChange={() => handleTabClick('tab-1')}
        />
        <label className="oncology-label" htmlFor="tab-1">Cardiac Services</label>
        <div className="oncology-panel">
          <h1>Cardiac Diagnostic Services</h1>
          <div className='separator'></div>
          <p>With high stress and pressure being a part of modern man's life, coronary ailments are on the rise today. Modern and sedentary lifestyle have made man prone to various heart and other lifestyle ailments. It has therefore become necessary that one undergo regular preventive health checkups as a precautionary measure</p>
          <br></br><br></br>
          <h2 className='heading2'>Procedure Of Valve Replacement Surgery</h2>
          <div className='separator'></div>
          <p>During an Aortic valve replacement surgery, an incision is made by cutting through the sternum. It is also known as median sternotomy. Following the incision being made, the pericardium is opened and subsequently the patient is placed on a cardiopulmonary bypass machine (heart-lung machine). The machine helps in pumping through the heart while the surgeon replaces the heart valve. Once the patient is on bypass, an incision is made in the aorta and a cross clamp is applied. Thereafter the surgeon removes the diseased aortic valve and replace it with the mechanical one. Once the valve is in place and the aorta has been closed, the patient is taken off from the cardiopulmonary bypass machine. Later,Transesophageal echocardiogramis done to confirm whether the new valve is functioning properly or not.
        </p>
        <br></br><br></br>
        <h2 className='heading2'>320 Slice CT Scanner</h2>
        <div className='separator'></div>
        <p>320 Slice CT Scanner can take the image of the entire heart in a single â€“rotation while it provides volumetric temporal resolution thus offering clearer image quality. It shows the whole organ anatomy along with the changes in it over the time resulting in better and quick diagnosis. Moreover, it can perform a detailed neurological examination too of the whole brain perfusion in a single study, helpful in identifying the level of heart disease
</p>
            <br></br><br></br>
            <h2 className='heading2'>Electrophysiology (EP) Study</h2>
            <div className='separator'></div>
            <p>EP Study is a specialized process that is undertaken by a trained cardiac specialist, who is called the Electrophysiologist. During this process, one or more thin, flexible wires, which are known as catheters, are put into a blood vessel, later guiding into the heart. Every catheter has got two or more electrodes for measuring the electrical signals to heart while they are travelling from one chamber to the other.    </p>
        
        </div>

        <input
          className="oncology-input"
          name="tabs"
          type="radio"
          id="tab-2"
          checked={activeTab === 'tab-2'}
          onChange={() => handleTabClick('tab-2')}
        />
        <label className="oncology-label" htmlFor="tab-2">Heart Transplant</label>
        <div className="oncology-panel">
          <h1>What Is A Heart Transplant?</h1>
          <div className='separator'></div>
          <p>A heart transplant is a surgical procedure in which a sick or non-functioning heart is replaced with a healthy one. The surgeon divides the left atrium and removes the heart of the patient by performing a transaction in the aorta, main pulmonary artery, and superior and inferior vena cavae. The pulmonary vein apertures are still present on the rear wall of the left atrium. The donor's heart is then joined to the recipient's by stitching the vena cavae, aorta, pulmonary artery, and left atrium together.
</p>
          <p>Someone who is either brain dead or on ventilator is the best candidate for a heart transplant. The healthy heart is removed from the patient and immersed in a solution before being transplanted. Heart transplantation in India has advanced to new heights as surgical methods and techniques have improved. Because the heart is such a vital life support system, cardiac transplantation is a common solution to cardiac issues. With hospital increases being successful for foreigners, heart transplants in India became financially effective.
</p>
         <br></br><br></br>
         <h2 className='heading2'>After A Heart Transplant, What To Expect</h2>
         <div className='separator'></div>
         <p>You will be held in the intensive care unit for a few days before being transferred to a general ward or room. It is a critical surgery, and you will remain in the hospital until you are able to move or stand up on your own. The length of time spent in the hospital, as well as the cost of a heart transplant in India, varies from person to person. You must stay at your outpatient transplant facility after being discharged from the heart transplant hospital to have your health status monitored. Patients choose to stay in a nearby place for the first three months due to the invasive nature of the surgery. At most hospitals you can get comfortable care. You can return to your house after three months, depending on your healthcare advise. Immunosuppressants, therapies, diets, and care plans, on the other hand, are supposed to be taken for the rest of one's life.
</p>
        <p>Biopsy is one of the post-surgical diagnostic techniques used to track how well the replacement heart is working. Fever, weariness, illness, weight gain, bodily swelling, shortness of breath, and other symptoms will be used to diagnose you. Let your team know if you see anything so they can look for rejection symptoms.
</p>
        </div>

        <input
          className="oncology-input"
          name="tabs"
          type="radio"
          id="tab-3"
          checked={activeTab === 'tab-3'}
          onChange={() => handleTabClick('tab-3')}
        />
        <label className="oncology-label" htmlFor="tab-3">Balloon Valvuloplasty</label>
        <div className="oncology-panel">
          <h1>What Is Balloon Valvuloplasty?</h1>
          <div className='separator'></div>
          <p>Balloon valvuloplasty a process used to open the aortic valve by using a balloon catheter. Balloon valvuloplasty is primarily used to treat patients who develop aortic stenosis, which is caused by calcification or scarring of the valve leaflets themselves. A balloon catheter is inserted into the blood vessel that feeds the heart and into the artery that feeds the affected valve. The balloon is inflated to deploy pressurized air that pushes apart valves, widening them so more blood can flow through.
</p>
          <br></br><br></br>
          <h2 className='heading2'>Diagnosis</h2>
          <p>This disease might come into consideration during a regular medical examination. But certain test can also be required to diagnose valve such as:
</p>
         <li className='heading-li'>On Auscultation: When a valve disorder is diagnosed, it is essential to understand what type it could be. The different sounds that are detectable are known as heart murmurs and they are caused by blood flowing through an abnormal valve in your heart.
</li>
         <li className='heading-li'>Echocardiogram: An echocardiogram or "echo" uses sound waves to make an image of your heart. It reveals the various constructions of the heart and may demonstrate any abnormalities on your valves. The echocardiogram gives the doctor a better picture of whether problems are present in your heart.
</li>
         <li className='heading-li'>Electrocardiogram (ECG)
</li>

          <br></br><br></br>
          <h2 className='heading2'>Reason For Treatment</h2>
          <p>You may need to have surgery if you are having symptoms due to narrowed heart valves, or if your valve disease has progressed to a serious stage. Doctors will examine you and determine if valvuloplasty or another treatment is right for your valve condition. Your doctor may recommend valvuloplasty if: You have severe valve narrowing and are having symptoms. You have narrowing of the mitral valve (mitral valve stenosis), even if you do not have symptoms. You have a narrowed tricuspid or pulmonary valve. You or your child has a narrowed aortic valve (aortic valve stenosis). However, the aortic valve tends to narrow again in adults who have had a valvuloplasty, so the procedure is usually done if you are too sick for surgery or are waiting for a valve replacement.
</p>
        </div>

        <input
          className="oncology-input"
          name="tabs"
          type="radio"
          id="tab-4"
          checked={activeTab === 'tab-4'}
          onChange={() => handleTabClick('tab-4')}
        />
        <label className="oncology-label" htmlFor="tab-4">Coronary Angioplasty</label>
        <div className="oncology-panel">
          <h1>What Is Coronary Angioplasty?</h1>
          <div className='separator'></div>
          <p>Coronary Angioplasty is a method of surgery used for treating cardiac disorders. Mostly this surgery is done in case of serious cardiac complications, especially just after a heart attack or a cardiac arrest. If a Coronary angioplasty is performed by a cardiac surgeon within the first few hours of a cardiac arrest or a heart failure or heart attack, then the surgery can effectively save the life of the patient and can also reduce the risk factors by huge proportions. But it must be remembered that, if the surgery after a heart attack or a cardiac arrest is delayed then it will surely increase complications and risk factors and can also lead to death. Doctors and cardiologists, in many cases, have found that after an angioplasty surgery, patients have gotten relief from the severe complications and symptoms of heart diseases.
</p>
            <p>In this procedure of cardiac surgery, the aim is to make the process of blood supply much smoother. coronary angioplasty surgery helps in opening the blood veins and vessels known as coronary arteries that supply blood to the heart so that the impure blood gets properly purified and the purified blood carrying oxygen can spread from the heart to different parts of the body.
</p>
          <br></br><br></br>
          <h2 className='heading2'>How Is Coronary Angioplasty Performed?</h2>
          <p>There are some specific procedures for performing a cardiac angioplasty surgery. First, the cardiologists and health care experts advise the anesthetist to perform either local or complete anesthesia. After the patient’s body and senses become numb, the doctor will perform a small incision either in the arm or within the groin. The nurse will gently insert a sac-like structure known as a catheter with a tiny inflatable balloon-like opening at the end into the arteries of the heart. The catheter is taken to the arteries and into those portions that are damaged by diagnosing the heart using an X-ray imaging technique and within the blocked portions of the arteries, the balloon-like structure is inflated. This helps in further widening the arterial walls and helps to move out the lipid deposits and other wastes such as fat and plaque in these arterial walls. After this surgery is done, purified blood can flow easily from the heart to the other parts of the body. The stent machine is also used in some cases after an angioplasty surgery that helps in keeping up with proper heartbeat and a good supply of blood from the heart to the other body parts because the stent machine helps in opening the arterial blood vessels and keeps the heart healthy by lowering the possibilities of further complications.
</p>
          <br></br><br></br>
          <h2 className='heading2'>Preparations Before Coronary Angioplasty Surgery</h2>
          <p>Before going for a coronary angioplasty surgery, it is crucial to talk to your cardiac surgeons and concerned health care experts and discuss thoroughly your health conditions, so that the surgery does not increase any further risks or complications for you. So, before the cardiac angioplasty surgery, the patient should be sure to discuss all the necessary measures and precautionary steps that should be taken before the surgery. Some of the most important steps to be followed before the cardiac angioplasty surgery are given here as follows:
</p>
            <li className='heading-li'>Give up excessive smoking of cigarettes, high consumption of caffeine, alcohol, or any other drugs such as aspirin, ibuprofen, and many other similar types of drugs that are harmful to your heart and the overall health.
</li>
            <li className='heading-li'>Talk to your doctor if you can continue to take medications and supplements that you are already having now.
</li>
            <li className='heading-li'>Consult and discuss with your doctor and concerned surgeons about the type of medications you take and whether you had any medical history or any history of cardiac disease in your previous medical cases
</li>
            <li className='heading-li'>Follow the instructions of the surgeon carefully and strictly.</li>

        </div>
       </div>
    </div>
    <Footer/>
    </>
  );
};

export default Cardio;
