import React from 'react';
import './WeCare.css'; // Import your custom CSS for styling
import image from '../../images/portrait-female-nurse-with-her-senior-patient-sitting-wheel-chair-removebg-preview (1)-min.png'

const WeCare = () => {
  return (
    <div className="we-care-container">
      <div className="text-content">
        <h2>We Care For You</h2>
        <p>
        At ABC Healthcare Solutions, we prioritize your health and well-being. Our team of dedicated professionals is committed to providing you with high-quality healthcare services tailored to your needs.
        </p>
      </div>
      <div className="image-content">
        <img src={image} alt="Placeholder" />
      </div>
    </div>
  );
};

export default WeCare;
