import React, { useState } from 'react';
import emailjs from 'emailjs-com'; // Import EmailJS library
import './CreateForm.css'; // Import CSS file for styling

const CreateForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contact: '',
    country: '',
    age: '',
    medicalCondition: '',
    message: '',
    file: null, // New state for file upload
  });

  const [formErrors, setFormErrors] = useState({
    name: '',
    email: '',
    contact: '',
    country: '',
    age: '',
    medicalCondition: '',
    message: '',
  });

  const [submissionStatus, setSubmissionStatus] = useState(null); // Track submission status

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });

    // Validate the field as the user types and update the error state
    validateField(name, value);
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      file: e.target.files[0], // Set the selected file in the state
    });
  };

  const validateField = (fieldName, value) => {
    // Your validation logic remains the same
    // ...
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let errors = {};

    // Basic validation
    if (!formData.name.trim()) {
      errors.name = 'Name is required';
    }

    if (!formData.email.trim()) {
      errors.email = 'Email is required';
    } else if (!isValidEmail(formData.email)) {
      errors.email = 'Invalid email format';
    }

    if (!formData.contact.trim()) {
      errors.contact = 'Contact number is required';
    } else if (!isValidContact(formData.contact)) {
      errors.contact = 'Invalid contact number format';
    }

    if (!formData.country.trim()) {
      errors.country = 'Country is required';
    }

    if (!formData.age.trim()) {
      errors.age = 'Age is required';
    } else if (!isValidAge(formData.age)) {
      errors.age = 'Invalid age';
    }

    if (!formData.medicalCondition.trim()) {
      errors.medicalCondition = 'Medical condition is required';
    }

    if (!formData.message.trim()) {
      errors.message = 'Message is required';
    }

    setFormErrors(errors);

    // If there are no errors, you can submit the form
    if (Object.keys(errors).length === 0) {
      // Prepare form data for submission
      const formDataToSend = new FormData();
      formDataToSend.append('name', formData.name);
      formDataToSend.append('email', formData.email);
      formDataToSend.append('contact', formData.contact);
      formDataToSend.append('country', formData.country);
      formDataToSend.append('age', formData.age);
      formDataToSend.append('medicalCondition', formData.medicalCondition);
      formDataToSend.append('message', formData.message);
      if (formData.file) {
        formDataToSend.append('file', formData.file);
      }

      // Send email using EmailJS
      emailjs.sendForm('service_t4gnwc6', 'template_txg9e8u', formDataToSend, 'x1dcyQhW_l39YNBOD')
        .then((result) => {
          console.log('Email sent successfully:', result.text);
          // Reset form after successful submission
          setFormData({
            name: '',
            email: '',
            contact: '',
            country: '',
            age: '',
            medicalCondition: '',
            message: '',
            file: null,
          });
          // Set submission status to true
          setSubmissionStatus(true);
        }, (error) => {
          console.error('Email sending failed:', error.text);
          // Set submission status to false
          setSubmissionStatus(false);
        });
    }
  };

  const isValidEmail = (email) => {
    // Basic email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValidContact = (contact) => {
    // Basic contact number validation regex (for example, allowing only digits and hyphens)
    const contactRegex = /^[0-9-]+$/;
    return contactRegex.test(contact);
  };

  const isValidAge = (age) => {
    // Basic age validation regex (for example, allowing only digits)
    const ageRegex = /^\d+$/;
    return ageRegex.test(age);
  };

  return (
    <div className="contact-form-container">
      {/* Left side: Map */}
      <div className="map-container">
        {/* Embed your map component here */}
        {/* For example: <MapComponent /> */}
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d220.74693298507069!2d85.33089913168635!3d27.718801370017538!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb191288c8f30f%3A0x64d98a3aa092b5fb!2sBhatbhateni%2C%20Kathmandu%2044600!5e0!3m2!1sen!2snp!4v1685858820815!5m2!1sen!2snp" width="100%" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
        </iframe>
      </div>

      {/* Right side: Email Form */}
      <div className="email-form-container">
        <h2>Please Leave A Message</h2>
        {submissionStatus === true && <p className="success-message">Form submitted successfully!</p>}
        {submissionStatus === false && <p className="error-message">Failed to submit form. Please try again later.</p>}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} placeholder="Enter your name" />
            {formErrors.name && <span className="error-message">{formErrors.name}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} placeholder="Enter your email" />
            {formErrors.email && <span className="error-message">{formErrors.email}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="contact">Contact Number</label>
            <input type="text" id="contact" name="contact" value={formData.contact} onChange={handleChange} placeholder="Enter your contact number" />
            {formErrors.contact && <span className="error-message">{formErrors.contact}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="country">Country</label>
            <input type="text" id="country" name="country" value={formData.country} onChange={handleChange} placeholder="Enter your country" />
            {formErrors.country && <span className="error-message">{formErrors.country}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="age">Age</label>
            <input type="text" id="age" name="age" value={formData.age} onChange={handleChange} placeholder="Enter your age" />
            {formErrors.age && <span className="error-message">{formErrors.age}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="medicalCondition">Medical Condition</label>
            <input type="text" id="medicalCondition" name="medicalCondition" value={formData.medicalCondition} onChange={handleChange} placeholder="Enter your medical condition" />
            {formErrors.medicalCondition && <span className="error-message">{formErrors.medicalCondition}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea id="message" name="message" value={formData.message} onChange={handleChange} placeholder="Enter your message"></textarea>
            {formErrors.message && <span className="error-message">{formErrors.message}</span>}
          </div>
          {/* File Upload Input */}
          <div className="form-group">
            <label htmlFor="file">Upload File</label>
            <input type="file" id="file" name="file" onChange={handleFileChange} />
          </div>
          <button type="submit">Send</button>
        </form>
      </div>
    </div>
  );
};

export default CreateForm;
