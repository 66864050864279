import React from 'react'
import Footer from '../../elements/Footer/Footer'
import Scroll from '../../elements/Scroll/Scroll'
import OurServiceText from './OurServiceText'
import ServiceCard from './ServiceCard'
import OfferService from './OfferService'
import ServiceText from './ServiceText'
import Serviceimage from './Serviceimage'
import Objectives from '../../elements/ObjectiveSection/Objectives'
import Service from '../../elements/Service/Service'
import ServicesCard from './ServicesCard'
import OurTeam from '../Teams/OurTeam'



const OurService = () => {
  return (
    <>

    <Serviceimage/>
    <ServiceText/>
    <OurServiceText/>
    <Service/>  
    <Scroll/>

     <Footer/>
    </>
  )
}

export default OurService
