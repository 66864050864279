import React from 'react'
import './TourismImage.css'

const TourismImage = () => {
  return (
    <>
    <div className='tourism-image-section animated-section'>
        <h2></h2>
    </div>
    </>
  )
}

export default TourismImage
