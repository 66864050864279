import React from 'react';
import './ContactImage.css';



const ContactImage = () => {
  return (
    <>
    <div className="contact-image-section  animated-section">
    
    </div>
    
   </>
  );
};

export default ContactImage;
