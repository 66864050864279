import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './TreatmentOb.css'; // Import CSS file
import bone from '../../images/bone.png'
import kidney from '../../images/kidney.png'
import liver from '../../images/liver.png'
import pancreas from '../../images/pancreas.png'
import heart from '../../images/heartt.png'
import cornea from '../../images/cornea.png'

const services = [
  { 
    icon: bone , 
    title: 'Bone Marrow Transplant', 
    description: (
      <div className='description-container'>
        <p>A bone marrow transplant is a procedure that infuses healthy blood-forming stem cells into your body to
        treat your broken or diseased bone marrow. A bone marrow transplant is also called a stem mobile
        transplant. A bone marrow transplant also referred to as a stem mobile transplant, is a procedure opted to
        treat some types of cancer. For example, you might have a transplant performed if you have leukaemia,
        more than one myeloma, or some kind of lymphoma.Doctors additionally can treat a few blood illnesses
        with stem mobile transplants. In the past, a stem cell transplant was more commonly referred to as a bone
        marrow transplant because the stem cells then were accrued from bone marrow. Today, stem cells are
        typically amassed from the blood, in preference over bone marrow. For this reason, the procedure now is
        more often referred to as a stem cell transplant. Bone marrow transplants may either use cells from your
        own body (autologous transplant) or from a donor (allogeneic transplant).</p>

        <p>Experience exceptional and fulfilling medical care in Nepal and India with us. Share your medical history
        reports for personalized assistance by emailing them to abchealthcaresolutions10@gmail.com or complete
        the form below to receive prompt guidance from our consultants.</p>
      </div>
    )
  },
  { 
    icon: liver , 
    title: 'Liver Transplant',
    description: (
      <div className='description-container'>
        <p>Liver Resection surgery involves various procedures on the liver to address different disorders. The most
        common operation entails excising a portion of the liver affected by disease. Liver resection is typically
        carried out to remove malignant tumors, either singular or multiple, localized to either the left or right
        side of the liver, with favorable survival rates.
        Patients undergoing liver resection undergo thorough evaluation by a multidisciplinary team to confirm
        the absence of extrahepatic tumors (outside the liver). Liver resections performed on patients with
        extrahepatic disease may alleviate symptoms caused by the tumor but offer limited improvement in
        survival.<br></br> Additionally, benign liver tumors such as cysts, adenomas, and hemangiomas can be effectively
        managed through liver resection. In cases where benign tumors are superficial and small in size,
        laparoscopic surgery may be performed, involving small abdominal punctures while utilizing a video
        camera for visualization.</p>

        <p>Liver resections are also conducted on individuals willing to donate part of their liver to a loved one,
          known as live donor liver transplant.
          A liver resection typically lasts between 3 to 5 hours and can often be performed without the need for
          blood transfusion, thus qualifying as a bloodless surgery. It is generally safe to remove up to 75% of the
          liver tissue. Hospitalization typically lasts about 5 days, with complete recovery occurring within 5 to 6
          weeks. The resected liver regenerates to its preoperative size within 6 to 8 weeks. Liver resections often
          yield excellent outcomes.<br></br>
          Experience exceptional and fulfilling medical care in Nepal and India with us. Share your medical history
          reports for personalized assistance by emailing them to abchealthcaresolutions10@gmail.com or
          complete the form below to receive prompt guidance from our consultants.</p>
      </div>
    )
  },
  { 
    icon: kidney, 
    title: 'Kidney Transplant', 
    description: (
      <div className='description-container'>
        <p>A kidney transplant is a surgical procedure performed to replace a diseased or failed kidney with a
        healthy kidney from a donor. It is typically recommended for individuals with end-stage kidney disease
        (ESRD), a condition in which the kidneys are no longer able to function effectively, often requiring
        dialysis to perform their filtering and waste removal functions. <br></br>
        The transplant process begins with a thorough evaluation of the recipient to assess their overall health and
        determine their suitability for transplantation. This evaluation includes medical tests, imaging studies, and
        consultations with various specialists.</p>
        <p className='bold'>Chronic renal failure can result from various conditions and factors that contribute to the gradual
        decline in kidney function.</p>
        <ul> 
        <li>Hypertension: High blood pressure can damage the blood vessels in the kidneys over time,
        leading to kidney damage.</li>
        <li>Diabetes: Uncontrolled diabetes can cause damage to the small blood vessels in the kidneys,
        leading to diabetic nephropathy.</li>
        <li>Glomerulonephritis: Inflammation of the glomeruli, the filtering units of the kidneys, can impair
         kidney function.</li>
        <li>Polycystic Kidney Disease: Inherited disorder characterized by the growth of cysts in the kidneys,
        leading to kidney damage and failure.</li>
        <li>Infections: Chronic or recurrent kidney infections can cause scarring and damage to the kidneys.</li>
        <li>Autoimmune Disorders: Conditions like lupus and vasculitis can lead to inflammation and
        damage to the kidneys.</li>
      </ul>

      <p className='bold'>Reasons for Successful Transplant in India:</p>
      <ul> 
        <li>Availability of Skilled Medical Professionals: India has highly skilled surgeons, nephrologists,
        and transplant teams with expertise in kidney transplantation.</li>
        <li>Advanced Medical Infrastructure: Leading hospitals in India are equipped with state-of-the-art
        facilities and advanced technology required for successful kidney transplants.</li>
        <li>Affordable Healthcare: Kidney transplantation in India is relatively more affordable compared to
        many other countries, making it accessible to a larger population.</li>
        <li>Increasing Awareness and Education: There is a growing awareness about kidney transplantation
          in India, leading to early detection of renal failure and increased acceptance of transplantation as
          a treatment option.</li>
        <li>Government Support: The Indian government has initiatives and programs aimed at promoting
        organ donation and transplantation, facilitating the transplant process.</li>
        <li>Legal Framework: India has a robust legal framework governing organ donation and
        transplantation, ensuring transparency, ethical practices, and protection of the rights of donors
        and recipients.</li>
      </ul>
      <p>Experience exceptional and fulfilling medical care in Nepal and India with us. Share your
        medical history reports for personalized assistance by emailing them to
        abchealthcaresolutions10@gmail.com or complete the form below to receive prompt guidance
        from our consultants.</p>

      </div>
    )
  },
  { 
    icon: pancreas, 
    title: 'Pancreas Transplant',
    description: (
      <div className='description-container'>
        <p>A pancreas transplant is a surgical procedure aimed at replacing a damaged or diseased pancreas with a
        healthy pancreas from a deceased donor. This procedure is typically performed in individuals with type 1
        diabetes whose diabetes is difficult to control with conventional treatments or who have experienced
        severe complications from the disease.<br></br>
        The transplant surgery involves removing the recipient is diseased pancreas and replacing it with the donor
        pancreas. In some cases, a simultaneous kidney transplant may also be performed in individuals with both
        type 1 diabetes and kidney failure, a condition known as type 1 diabetes with end-stage renal disease
        (ESRD).</p>
        <p>After the transplant, recipients require lifelong immunosuppressive medications to prevent rejection of the
        donor pancreas. These medications suppress the immune system's response, reducing the risk of the body
        attacking and damaging the transplanted organ.
        Pancreas transplantation can significantly improve the recipient's quality of life by eliminating the need
        for insulin injections and providing better control over blood sugar levels. It can also reduce the risk of
        diabetes-related complications, such as kidney disease, nerve damage, and cardiovascular issues.</p>
        <p>Experience exceptional and fulfilling medical care in Nepal and India with us. Share your medical history
        reports for personalized assistance by emailing them to abchealthcaresolutions10@gmail.com or
        complete the form below to receive prompt guidance from our consultants.</p>
      </div>
    )
  },
  { 
    icon: heart , 
    title: 'Heart Transplant',
    description: (
      <div className='description-container'>
        <p>Heart transplant surgery is a critical procedure that involves the replacement of a damaged or diseased
        heart with a healthy one obtained from a deceased donor. Typically considered the last resort for
        individuals with advanced heart failure, heart transplantation offers a chance for renewed health and
        vitality when all other treatment options have been exhausted. Various underlying conditions may lead to
        heart failure, including coronary heart disease, heart valve disorders, weakened heart muscles, congenital
        heart defects, or viral infections of the heart. </p>

        <p>Despite its life-saving potential, heart transplant surgery carries significant risks. These risks include
        rejection of the transplanted heart by the recipient's immune system, complications from
        immunosuppressive medications used to prevent rejection, infections, and surgical complications.
        However, with careful monitoring, appropriate treatment, and regular medical care, many of these risks
        can be mitigated or managed effectively.</p>
        <p>Following heart transplant surgery, most patients can gradually resume their normal levels of activity.
        However, it's essential to note that fewer than 30 percent of heart transplant recipients return to work for
        various reasons. Factors such as recovery time, physical limitations, ongoing medical monitoring, and
        personal circumstances may influence a patient's ability to return to work after surgery. It's crucial for
        patients to work closely with their healthcare team to optimize their recovery and adjust to any lifestyle
        changes following heart transplantation.</p>
        <p>Experience exceptional and fulfilling medical care in Nepal and India with us. Share your medical history
        reports for personalized assistance by emailing them to abchealthcaresolutions10@gmail.com or
        complete the form below to receive prompt guidance from our consultants.</p>
      </div>
    )
  },
  { 
    icon: cornea , 
    title: 'Cornea Transplant',
    description: (
      <div className='description-container'>
        <p> Corneal transplantation, also known as keratoplasty, involves replacing a damaged or diseased cornea
        with a healthy cornea from a deceased donor. It is commonly performed to restore vision in individuals
        with corneal diseases or injuries.</p>
        <p> These are some of the most common types of organ transplants performed today. Each type of transplant
        requires careful evaluation, donor matching, surgical expertise, and lifelong medical management to
        ensure the best possible outcomes for recipients.</p>
        <p>Experience exceptional and fulfilling medical care in Nepal and India with us. Share your medical history
        reports for personalized assistance by emailing them to abchealthcaresolutions10@gmail.com or
        complete the form below to receive prompt guidance from our consultants.</p>
      </div>
    )
  }
];

const OrganCard = () => {
  const [expanded, setExpanded] = useState({});

  const toggleDescription = (index) => {
    setExpanded(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  return (
    <section id="treatment-services">
      <Container>
        <h2 className="text-center mb-5 treatment-colored-heading" ></h2>
        <Row className="d-flex justify-content-center">
          {services.map((service, index) => (
            <Col key={index} xs={12} md={6} lg={3} className="treatment-service-item">
              <div>
                <img src={service.icon} alt={service.title} className="treatment-service-icon" /> 
                <h3 className="treatment-service-title">{service.title}</h3>
                <div className='treatment-service-description'>
                  {expanded[index] ? service.description : <p>{service.description.props.children[0]}</p>}
                </div>
                <Button variant="link" onClick={() => toggleDescription(index)} className='show-more-button'>
                  {expanded[index] ? 'Show Less' : 'Show More'}
                </Button>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default OrganCard;
