import React from 'react'
import './ServiceText.css'

const ServiceText = () => {
  return (
    <>
    <div className='servicetext-section'>
        <h2>Our Services</h2>
    </div>
      
    </>
  )
}

export default ServiceText
