import React, { useState } from 'react';
import './Oncology.css';
import OrganImage from '../OrganImage';
import Footer from '../../../elements/Footer/Footer';

const Ent = () => {
  const [activeTab, setActiveTab] = useState('tab-1');

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <>
    <OrganImage/>
    <div className='oncology-container'>
      <div className="oncology-tabs">
        <input
          className="oncology-input"
          name="tabs"
          type="radio"
          id="tab-1"
          checked={activeTab === 'tab-1'}
          onChange={() => handleTabClick('tab-1')}
        />
        <label className="oncology-label" htmlFor="tab-1">Corneal Transplant</label>
        <div className="oncology-panel">
          <h1>What Is A Corneal Transplant Surgery?</h1>
          <div className="separator"></div>
          <p>Corneal transplant surgery is a type of surgery which is also known as the corneal grafting, in this advanced surgical process, the damaged or diseased cornea is replaced by the healthy corneal tissue (the graft). It is an important surgery because it is used to restore the functional vision of the human eye.</p>
          <p>The cornea is a type of transparent layer. This transparent layer is like a domed-shaped structure. It helps in protecting the eye from harmful external agents. These external agents can be dirt, germs, and other particles. Moreover, it also helps protect the eye from harmful ultraviolet rays. Another major task of the cornea is to focus the intake of the light according to the surroundings. It is responsible for limiting the amount of required light in the eye. Therefore, that is how it contributes to providing a clear vision. Corneal transplant surgery helps to restore the vision of the person. Surgery is applicable or suggested by doctors if the medicines, as well as other methods, are not effective in the treatment.</p>
           

            <br></br><br></br>
          <h2 className='heading2'>Preparation Before Corneal Transplant Surgery</h2>
          <div className="separator"></div>
          <p>Before going for the corneal transplant, a thorough eye examination, as well as diagnosis, is a must. Moreover, the recommendation of the doctor is very important. So, during the eye examination as well as diagnosis, the doctors will evaluate and check the condition of the eye. This examination helps them in the surgery as well as after the surgery (conditions that can cause complications post-surgery). Apart from this, other different factors need to be taken into consideration, for instance, the size of the eyes. Hence, measuring the size of the eyes is a very important task. Measuring the size helps in determining the size of the donor’s cornea. Before the surgery, your doctor might ask you to stop taking certain medications or supplements. Moreover, some infections can cause unsuccessful corneal transplants. Hence, the infection needs to be treated first and after that, you can proceed with the surgery. Your doctor might recommend you take antibiotic eye drops to reduce the chances of eye infection. Most of the time, these corneal transplant surgeries are done as outpatient procedures, which means the patient can go home the very same day. The patient need not eat anything before the surgery. He or she will be asked to do so the night before the surgery. The patient can have apple juice, water, and plain coffee or tea (without cream or sugar) for up to two hours before surgery.</p>
            <br></br><br></br>
           <h2 className='heading2'>What Happens During Corneal Transplant Surgery?</h2>
           <div className="separator"></div>
           <p>In most cases, the surgery is done on an outpatient basis. During the surgery, the patient will be given a sedative to help him or she relax. After that, he or she will be getting anesthesia to make the eye numb and after that, the eye will be washed and cleansed. Now, the eye is still, and the doctors proceed with the procedure the surgery. In this procedure, the doctors make highly precise cuts on the cornea for its removal. The whole process is done under a microscope. An object known as a trephine which looks like a circular cookie cutter instrument is used to remove the center of the diseased or damaged cornea. Throughout the process, the overall surgery is done on the central portion of the cornea. After that, the surgeon excises an appropriate circular disc of the patient’s cornea and replaces it with a similarly sized disc of the donor’s cornea, which is then sutured into place.</p>
            <br></br><br></br>
            <h2 className='heading2'>During The Recovery The Patient Might Feel</h2>
            <div className='separator'></div>
            <li className='heading-li'>You need to wear an eye patch to make the top layer of the cornea heal.</li>
            <li className='heading-li'>The eye will remain red or sensitive to light.</li>
            <li className='heading-li'>Patients might feel soreness as well as pain.</li>
            <li className='heading-li'>Need to take prescribed medication to bring down the inflammation.</li>
            <li className='heading-li'>The full-thickness corneal transplant requires more time to get healed, the partial corneal transplant requires less time to get healed.</li>

          
            <br></br><br></br>
            <h2 className='heading2'>Risk Factors A Patient Might Face After The Surgery</h2>
            <div className='separator'></div>
            <li className='heading-li'>Increased risk of having cataracts</li>
            <li className='heading-li'>Having an infection in the eye</li>
            <li className='heading-li'>Having the problem of glaucoma (high pressure inside the eye)</li>
            <li className='heading-li'>Rejection of the new cornea by the recipient's body.</li>
            <li className='heading-li'>Problems with the stitches used to attach the new cornea.</li>
            <li className='heading-li'>Swelling in the cornea.</li>
        </div>


       



       
       {/* <input
          className="oncology-input"
          name="tabs"
          type="radio"
          id="tab-6"
          checked={activeTab === 'tab-6'}
          onChange={() => handleTabClick('tab-6')}
        />
        <label className="oncology-label" htmlFor="tab-6">Prostate Cancer</label>
        <div className="oncology-panel">
          <h1>Tekno Match</h1>
          <p>Tekno Match (Citrus ×clementina) is a hybrid between a mandarin orange and a sweet orange, so named in 1902. The exterior is a deep orange colour with a smooth, glossy appearance. Clementines can be separated into 7 to 14 segments. Similarly to tangerines, they tend to be easy to peel.</p>
        </div>
  */}
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default Ent;
