import React from 'react'
import './InternationalText.css'

const InternationalText = () => {
  return (
    <>
    <div className='international-text-section'>
        <h2>International Hospitals</h2>
    </div>
      
    </>
  )
}

export default InternationalText
