import React from 'react'
import './AboutText.css'

const AboutText = () => {
  return (
    <>
      <div className='abouttext-section'>
        <h2>About Us</h2>
      </div>
    </>
  )
}

export default AboutText
