import React, { useState } from 'react';
import image from '../../images/abc.png';
import { Link } from 'react-router-dom';
import './Navbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faEnvelope, faMobile, faLocation, faSquarePhone , faTimes} from '@fortawesome/free-solid-svg-icons';
import { LinkedIn } from '@mui/icons-material';
import TopDesc from './TopDesc';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isTreatmentsDropdownOpen, setIsTreatmentsDropdownOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
    
  };

  const toggleTreatmentsDropdown = () => {
    setIsTreatmentsDropdownOpen(!isTreatmentsDropdownOpen);
    
  };

  const handleLinkClick = () => {
    setIsOpen(false);
  };
  


  return (
    <>
     
      <TopDesc/>
      <nav className="navbar">
        <div className="logo">
          <img src={image} alt="ABC Logo" />
        </div>
        <div className={`menu-toggle ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
        <ul className={`nav-links ${isOpen ? 'open' : ''}`}>
          <li><Link to="/"  onClick={handleLinkClick}>Home</Link></li>
          <li><Link to="/about"  onClick={handleLinkClick}>About Us</Link></li>
          <li><Link to="/ourservice"  onClick={handleLinkClick}>Services</Link></li>
          <li className="dropdown" onClick={toggleTreatmentsDropdown}>
            <span>Treatments</span>
            <FontAwesomeIcon icon={isTreatmentsDropdownOpen ? faTimes : faAngleDown} className="dropdown-icon" />
            {isTreatmentsDropdownOpen && (
              <ul className="dropdown-menu">
               <li><Link to="/treatments/oncology" onClick={handleLinkClick}>Oncology </Link></li>
               <li><Link to="/treatments/orthopaedic"  onClick={handleLinkClick}>Orthopedics</Link></li>
               <li><Link to="/treatments/bariatric"  onClick={handleLinkClick}>Bariatric Surgery </Link></li>
               <li><Link to="/treatments/cardio"  onClick={handleLinkClick}>Cardiology </Link></li>
               <li><Link to="/treatments/cosmetic"  onClick={handleLinkClick}>Cosmetic Surgery </Link></li>
               <li><Link to="/treatments/hematology"  onClick={handleLinkClick}>Hematology </Link></li>
               <li><Link to="/treatments/transplant"  onClick={handleLinkClick}>Transplant </Link></li>
               <li><Link to="/treatments/gynae"  onClick={handleLinkClick}>Gynaecology </Link></li>
               <li><Link to="/treatments/gastro"  onClick={handleLinkClick}>Gastroenterology </Link></li>
               <li><Link to="/treatments/spine"  onClick={handleLinkClick}>Spine Surgery </Link></li>
               <li><Link to="/treatments/nephrology"  onClick={handleLinkClick}>Nephrology </Link></li>
               <li><Link to="/treatments/pediatric"  onClick={handleLinkClick}>Pediatric</Link></li>
               <li><Link to="/treatments/ent"  onClick={handleLinkClick}>ENT </Link></li>
              <li><Link to="/treatments/organ"  onClick={handleLinkClick}>Organ Transplant </Link></li>
              </ul>
            )}
          </li>
          <li className="dropdown" onClick={toggleDropdown}>
            <span>Hospitals Network</span>
            <FontAwesomeIcon icon={faAngleDown} className="dropdown-icon"/>
            {isDropdownOpen && (
              <ul className="dropdown-menu">
                <li><Link to="/hospitals/local" onClick={handleLinkClick}>National Hospitals</Link></li>
                <li><Link to="/hospitals/international"  onClick={handleLinkClick}>International Hospitals</Link></li>
              </ul>
            )}
          </li>
          <li><Link to="/medicaltourism"  onClick={handleLinkClick}>Medical Tourism</Link></li>
          <li><Link to="/contact"  onClick={handleLinkClick}>Contact Us</Link></li>
          
          <li>
            <a href="mailto:your.email@example.com">
              <FontAwesomeIcon icon={faEnvelope} />
            </a>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default Navbar;
