// Counting.js

import React, { useState, useEffect } from 'react';
import './Counting.css'; // Import CSS file for styling
import { Parallax } from 'react-parallax';
import Count from '../../images/back.jpg';

const Counting = () => {
  const [counts, setCounts] = useState({
    count1: 0,
    count2: 0,
    count3: 0,
    count4: 0
  });

  const targets = [5, 100, 15000, 200];
  const duration = 3000; // Duration of animation in milliseconds

  useEffect(() => {
    const timers = targets.map((target, index) => {
      const increment = Math.ceil(target / (duration / 1000)); // Calculate increment per second
      let currentCount = 0;
      return setInterval(() => {
        currentCount = Math.min(currentCount + increment, target);
        setCounts(prevCounts => ({
          ...prevCounts,
          [`count${index + 1}`]: currentCount
        }));
        if (currentCount === target) clearInterval(timers[index]);
      }, 1000); // Update every second
    });

    return () => {
      timers.forEach(timer => clearInterval(timer));
    };
  }, [targets]);

  return (
    <Parallax strength={400} bgImage={Count}>
      <div className='counting-container'>
        <h1>Humble Beginning</h1>
        <p>Global Footprints</p>
        <div className="count-cards">
          {targets.map((target, index) => (
            <div key={index} className="count-card">
              <div className="icon-circle">
                {index === 0 && <i className="fas fa-globe fa-3x"></i>}
                {index === 1 && <i className="fas fa-hospital-alt fa-3x"></i>}
                {index === 2 && <i className="fas fa-hands-helping fa-3x"></i>}
                {index === 3 && <i className="fas fa-user-md fa-3x"></i>}
              </div>
              <span>{counts[`count${index + 1}`]}</span>
              <p>
                {target === 5 && "Countries"}
                {target === 100 && "Network Hospitals"}
                {target === 15000 && "Patient Help"}
                {target === 200 && "Network Doctors"}
              </p>
            </div>
          ))}
        </div>
      </div>
    </Parallax>
  );
};

export default Counting;
