import React, { useState } from 'react';
import './Oncology.css';
import Footer from '../../../elements/Footer/Footer';
import OrganImage from '../OrganImage';

const Orthopaedic = () => {
  const [activeTab, setActiveTab] = useState('tab-1');

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <>
    <OrganImage/>
    <div className='oncology-container'>
      <div className="oncology-tabs">
        <input
          className="oncology-input"
          name="tabs"
          type="radio"
          id="tab-1"
          checked={activeTab === 'tab-1'}
          onChange={() => handleTabClick('tab-1')}
        />
        <label className="oncology-label" htmlFor="tab-1">Elbow Surgery</label>
        <div className="oncology-panel">
          <h1>What Is Elbow Replacement Surgery?</h1>
          <div className='separator'></div>
          <p>The elbow joint is a ‘hinge’ formed by the bones of the forearm with the long lower arm bone. The main functions of this joint are to enable movement in all directions and enable you to straighten your arm against gravity. Elbow Joint Replacement Surgery can be done if you have damaged or deformed bones due to an injury or accident, malformation, or arthritis. It is mostly done on patients who have had fractures in their elbow joint.</p>
          <br></br><br></br>
          <h2 className='heading2'>At What Stage Elbow Replacement Surgery Needed?</h2>
          <div className='separator'></div>
          <p>Elbow joint replacement surgery is a medical procedure that involves replacing the damaged cartilage or bone in your elbow joint with implant materials. If you have suffered from an injury that has damaged the cartilage lining of your elbow joint, such as a fracture or arthritis, Elbow Joint Replacement Surgery might be recommended. Aside from these injuries, there are some situations when doctors may recommend elbow joint replacement surgery.
        </p>
        <p>Osteoarthritis, bad results from previous elbow surgery, rheumatoid arthritis, post-traumatic arthritis, a badly broken bone in the upper or lower arm near the elbow, and badly damaged tissues in the elbow are all reasons why this treatment would be a great choice.
</p>
        <br></br><br></br>
        <h2 className='heading2'>Benefits Of Elbow Replacement Surgery</h2>
        <div className='separator'></div>
        <p>The benefits of elbow replacement surgery are many. If you suffer from chronic pain, stiffness, and limited range of elbow motion, elbow replacement surgery may give you your life back. Elbow replacement surgery is usually needed to relieve pain and stiffness, improve elbow movement, and reduce the risk of further damage to your elbow. During this procedure, a surgeon replaces the damaged parts of your joint with metal or plastic surfaces. You will experience an increase in flexibility and comfort after surgery resulting in improved quality of life and arm strength.
</p>
            <br></br><br></br>
            <h2 className='heading2'>Elbow Replacement Surgery Procedure</h2>
            <div className='separator'></div>
            <p>The surgeon will first make an incision at the back of your elbow. The surgeon then removes the scar tissues, spikes, and other tissues around the joint using special instruments. After that, a metal plate is placed over the humerus bone to hold it in place while the ulna is restored to its correct position.
            The treatment for a broken elbow involves surgery. The doctor will first put the stem into the humerus and ulna bones, and use bone cement to hold it in place. An incision will then be made and the broken ends of your bones are placed back together. The incision will be covered with a dressing and you must keep it clean.</p>
            <p>Pediatric orthopedic surgery specialists use an arthrotomy procedure to deplete the joint of careful liquid. A transverse tube will be effectively inserted into your joint and evacuated in your emergency hospital room within the first few days after a medical procedure.
</p>
        </div>

        <input
          className="oncology-input"
          name="tabs"
          type="radio"
          id="tab-2"
          checked={activeTab === 'tab-2'}
          onChange={() => handleTabClick('tab-2')}
        />
        <label className="oncology-label" htmlFor="tab-2">Shoulder Surgery</label>
        <div className="oncology-panel">
          <h1>What Is A Shoulder Replacement Surgery?</h1>
          <div className='separator'></div>
          
            <p>Shoulder surgery is the surgical procedure to repair a torn or damaged rotator cuff. The rotator cuff are four tendons that help you lift your arm from you shoulder joint, along with other arm movement. Shoulder surgery can be done in two ways: arthroscopic and open. By removing damaged tissue and repairing damaged joints, a surgeon can restore normal function to your shoulder. Surgery helps to relieve pain and improve the range of motion. The artificial joint is designed to last at least 15 years but may wear out sooner if you have rheumatoid arthritis or a medical condition that affects other joints, such as diabetes mellitus, pseudogout and gout. Shoulder Joint Replacement Surgery involves either total removal of the entire shoulder joint (total shoulder) or only partial removal (subacromial decompression).
</p>
         <br></br><br></br>
         <h2 className='heading2'>Are You A Candidate For Shoulder Replacement Surgery?</h2>
         <div className='separator'></div>
        <p>These are the most common reasons why shoulder replacement surgery is recommended. If you are suffering from any of these symptoms or have a history of shoulder injury, you may be a candidate for shoulder joint replacement.
</p>
        <p>Shoulder pain is a major unmet need for many people who are suffering from weakness in their shoulders. Patients may struggle with daily tasks such as bathing, washing, toileting and dressing. Although there are treatments such as anti-inflammatory medicines, cortisone injections and physiotherapy, these do not always relieve the patient’s pain and can result in other serious side effects if used long term.
</p>
        </div>

        <input
          className="oncology-input"
          name="tabs"
          type="radio"
          id="tab-3"
          checked={activeTab === 'tab-3'}
          onChange={() => handleTabClick('tab-3')}
        />
        <label className="oncology-label" htmlFor="tab-3">Hand & Wrist Surgery</label>
        <div className="oncology-panel">
          <h1>What Is The Hand Wrist Surgery Procedure?</h1>
          <div className='separator'></div>
            <p>Hand and wrist problems can affect anybody, young or old, and they can influence your employment and quality of life. These complicated sections of your body equip you with the power to produce significant force for activities such as rock climbing. They also provide you with the fine dexterity required to perform a musical instrument, making them a perfect blend of power and grace. Orthopedic surgeons are experts in the difficulties of microvascular surgery as well as the anatomy of the hand and wrist. This allows us to diagnose and treat the little bones, delicate nerves, arteries, and tendons that may be causing your hand or wrist pain.

            </p>
            <p>Surgical or non-surgical treatment options may be indicated based on the diagnosis. Wrist arthroscopy allows your doctor to examine the ligaments and cartilage surfaces of the bones. Some ligaments have a good blood supply and may be mended and healed, while others do not have a blood supply and cannot be fixed or healed, thus they are removed.
            </p>
                    <br></br><br></br>
          <h2 className='heading2'>Release Of The Carpal Tunnel Syndrome</h2>
            <p>Carpal tunnel syndrome develops when the median nerve is pressed on the carpal tunnel ligament as it passes through your wrist. Pins and needles and numbness in your fingertips are the outcome of this. Sometimes surgery is required to relieve pressure on the nerve. The carpal tunnel ligament is split under local anesthesia to accomplish this. It is unlikely that you will need to spend the night in the hospital.
            </p>
            <p>Following carpal tunnel surgery, after the operation, you may need to wear a large bandage on your wrist and hand for a week or two. Within 10–14 days, your stitches will be removed. You can use your fingers and thumb at this time, but heavy work should be avoided. It is critical to move your fingers to avoid the nerves and tendons becoming entangled in any scar tissue that may form following your surgery. It should take less than a month for you to recover from the surgery's effects, however, it may take longer to regain all your sensations, especially if you have had carpal tunnel syndrome for a long period. The scar may pain and be sensitive for a few months in a tiny percentage of people, but this normally goes away without further treatment.
                </p>

          <br></br><br></br>
          <h2 className='heading2'>Know More About Recovery</h2>
            <p>After your operation, a bandage will keep your wrist from moving. It will protect the region and provide pain relief. Arthroscopy would not necessitate an overnight stay in the hospital. Fingers may move freely, and this movement helps to reduce edema and rigidity. Your surgeon will walk you through the therapy, medication, healthy exercise, and any work restrictions. Raising the wrist after wrist surgery is crucial to avoid swelling and pain
           </p>
        </div>

        <input
          className="oncology-input"
          name="tabs"
          type="radio"
          id="tab-4"
          checked={activeTab === 'tab-4'}
          onChange={() => handleTabClick('tab-4')}
        />
        <label className="oncology-label" htmlFor="tab-4">Foot and Ankle Surgery</label>
        <div className="oncology-panel">
          <h1>What Is Foot And Ankle Replacement Surgery?</h1>
          <div className='separator'></div>
            <p>Foot and Ankle Replacement Surgery is a type of surgery on the foot and ankle that can be performed to fix injuries or correct deformities. It can be used for any number of purposes, including to remove tumors, treat fractures, treat infections, relieve pain, or repair ruptured ligaments. The goal of surgery is to help you regain your ability to walk and move freely, while preventing the development of new problems in the years ahead.
            </p>
          <br></br><br></br>
          <h2 className='heading2'>How Is Coronary Angioplasty Performed?</h2>
          <p>There are some specific procedures for performing a cardiac angioplasty surgery. First, the cardiologists and health care experts advise the anesthetist to perform either local or complete anesthesia. After the patient’s body and senses become numb, the doctor will perform a small incision either in the arm or within the groin. The nurse will gently insert a sac-like structure known as a catheter with a tiny inflatable balloon-like opening at the end into the arteries of the heart. The catheter is taken to the arteries and into those portions that are damaged by diagnosing the heart using an X-ray imaging technique and within the blocked portions of the arteries, the balloon-like structure is inflated. This helps in further widening the arterial walls and helps to move out the lipid deposits and other wastes such as fat and plaque in these arterial walls. After this surgery is done, purified blood can flow easily from the heart to the other parts of the body. The stent machine is also used in some cases after an angioplasty surgery that helps in keeping up with proper heartbeat and a good supply of blood from the heart to the other body parts because the stent machine helps in opening the arterial blood vessels and keeps the heart healthy by lowering the possibilities of further complications.
</p>
          <br></br><br></br>
          <h2 className='heading2'>Preparations Before Coronary Angioplasty Surgery</h2>
          <p>Before going for a coronary angioplasty surgery, it is crucial to talk to your cardiac surgeons and concerned health care experts and discuss thoroughly your health conditions, so that the surgery does not increase any further risks or complications for you. So, before the cardiac angioplasty surgery, the patient should be sure to discuss all the necessary measures and precautionary steps that should be taken before the surgery. Some of the most important steps to be followed before the cardiac angioplasty surgery are given here as follows:
</p>
            <li className='heading-li'>Give up excessive smoking of cigarettes, high consumption of caffeine, alcohol, or any other drugs such as aspirin, ibuprofen, and many other similar types of drugs that are harmful to your heart and the overall health.
</li>
            <li className='heading-li'>Talk to your doctor if you can continue to take medications and supplements that you are already having now.
</li>
            <li className='heading-li'>Consult and discuss with your doctor and concerned surgeons about the type of medications you take and whether you had any medical history or any history of cardiac disease in your previous medical cases
</li>
            <li className='heading-li'>Follow the instructions of the surgeon carefully and strictly.</li>

        </div>
       </div>
    </div>
    <Footer/>
    </>
  );
};

export default Orthopaedic;
