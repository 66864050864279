import React from 'react'
import TourismText from './TourismText'
import TourismImage from './TourismImage'
import Footer from '../../elements/Footer/Footer'
import TourismHero from './TourismHero'
import TourismService from './TourismService'
import Scroll from '../../elements/Scroll/Scroll'

const Tourism = () => {
  return (
    <>
    <TourismImage/>
    <TourismText/>
    <TourismHero/>
    <TourismService/>
    <Scroll/>
    <Footer/>
      
    </>
  )
}

export default Tourism
