import React, { useState } from 'react';
import './Oncology.css';
import Footer from '../../../elements/Footer/Footer';
import OrganImage from '../OrganImage';

const Gastro = () => {
  const [activeTab, setActiveTab] = useState('tab-1');

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <>
    <OrganImage/>
    <div className='oncology-container'>
      <div className="oncology-tabs">
        <input
          className="oncology-input"
          name="tabs"
          type="radio"
          id="tab-1"
          checked={activeTab === 'tab-1'}
          onChange={() => handleTabClick('tab-1')}
        />
        <label className="oncology-label" htmlFor="tab-1">Gall Bladder Surgery</label>
        <div className="oncology-panel">
          <h1>What Is Gallbladder Surgery (Cholecystectomy)?</h1>
          <div className='separator'></div>
          <p>Gallbladder surgery is a type of surgery that is done when the person is going through extreme pain because of the gallstones that are stuck in the gallbladder and obstruct the flow of the bile juice from the gallbladder. Gallstones are small entities that get accumulated in the gallbladder. The procedure of cholecystectomy is a very minimally invasive procedure. In this procedure the surgeon makes a small incision in the abdomen and this incision allows him or her to gain access to the gallbladder with the help of the surgical tools. These surgical tools also include a tool named laparoscope (it is a high-definition camera), it is used to see and analyze the condition of the internal organ of the human body. Moreover, in some cases the surgeon can also make big incisions as well, these big incisions are made to remove the gallbladder and the procedure is known as open Cholecystectomy.</p>
          <br></br><br></br>
          <h2 className='heading2'>What Are The Symptoms Of Gallstones In Gallbladder?</h2>
          <div className='separator'></div>
          <p>Gallstones are abnormal stone-like masses. These stone-like masses are mostly undissolved cholesterol. And these undissolved cholesterol mass accumulated in the gallbladder and formed this stone like shape. Because of these stone-like masses, a person can feel extreme levels of pain in the right side of the abdomen. Before moving forth, understand why these gallstones formed in the body or what are the causes that are behind their formation</p>
          <li className='heading-li'>Gallstones usually occur in people who are overweight and come in the category of obese.</li>
          <li className='heading-li'>Another reason is that gallstones also occur because of the excessive estrogen from numerous pregnancies.</li>
          <li className='heading-li'>Gallstones can also occur because of hormone replacement therapy, or by birth control pills. This may result in increased cholesterol levels in bile, and it slows down the flow of bile from gallbladder and that leads to the formation of gallstones.</li>
          <li className='heading-li'>People who have biliary infections can also develop gallstones.</li>
          <li className='heading-li'>Excessive dieting and consuming cholesterol reducing drugs can also result in gallstone formation.</li>
          <p>Now the question comes how one can analyze that they have these stone like masses in their gallbladder or what are the symptoms that are responsible for the same. The very simple answer to this question is if a person is experiencing severe abdominal pain. This gallstone pain is also known as the gallstone attack (colic). Gallstones attacks usually or happen in the nights and these attacks happen because of consuming fatty meals. Gallstones can be asymptomatic, and stones can be called silent stone. But if a person has a gallstone attack, he or she might face:</p>
          <li className='heading-li'>If a person is having pain under their right shoulder.</li>
          <li className='heading-li'>If a person is having a feeling of nausea or vomiting.</li>
          <li className='heading-li'>If a person is having pain in the back between the shoulder blades regions.</li>
          <li className='heading-li'>A burst of severe pain in the region of the right upper abdomen and it increases very rapidly. Moreover, the pain will last from a few minutes to several hours.</li>
        </div>


        <input
          className="oncology-input"
          name="tabs"
          type="radio"
          id="tab-2"
          checked={activeTab === 'tab-2'}
          onChange={() => handleTabClick('tab-2')}
        />
        <label className="oncology-label" htmlFor="tab-2">Laparoscopic Surgery</label>
        <div className="oncology-panel">
          <h1>What Is Laparoscopic Surgery?</h1>
          <div className='separator'></div>
            <p>Laparoscopic surgery is also known by the name of minimally invasive surgery or by the name of keyhole surgery. There are numerous operations that can be performed with the help of laparoscopic technology. As the technique allows medical experts to analyze and examine the patient without having any serious invasive incision. Moreover, the success rate is very high in laparoscopic surgery. A wide range of operations that can be conducted are as follows:</p>
            <li className='heading-li'>Laparoscopic hernia repair</li>
            <li className='heading-li'>Laparoscopic spine surgery</li>
            <li className='heading-li'>Laparoscopic cholecystectomy</li>
            <li className='heading-li'>Laparoscopic appendix removal</li>
            <br></br><br></br>
            <h2 className='heading2'>Why Should One Go For Laparoscopic Surgery?</h2>
            <div className='separator'></div>
            <p>There are so many reasons to choose laparoscopic surgery. Laparoscopic surgery’s main objective is to perform the surgery in order to aid the disorders or diseases found in the pelvic or abdominal organs of the patient. The disease might be related to a reproductive infection or infertility or intestinal infection etc. The surgery can be done via open surgery or minimal incision method. Thus, if your doctors prescribe it, you can easily go for it.</p>
            <br></br><br></br>
            <h2 className='heading2'>What Are The Benefits Of Laparoscopy?</h2>
            <div className='separator'></div>
            <p>Benefits of laparoscopy surgery include a faster recovery and minimal scarring. Surgeons can use mini-scopes and other tools to perform the procedure. It's also the only effective treatment for certain conditions such as endometriosis, ovarian cysts and ectopic pregnancies.</p>
            <li className='heading-li'>Less pain</li>
            <li className='heading-li'>Smaller scars</li>
            <li className='heading-li'>Faster recovery</li>
            <li className='heading-li'>Small incisions</li>
            <li className='heading-li'>Shorter hospital stay</li>
            <li className='heading-li'>Less chances of wound infection</li>
            <li className='heading-li'>Quicker return to full activities</li>

        </div>

        <input
          className="oncology-input"
          name="tabs"
          type="radio"
          id="tab-3"
          checked={activeTab === 'tab-3'}
          onChange={() => handleTabClick('tab-3')}
        />
        <label className="oncology-label" htmlFor="tab-3">Liver Transplant</label>
        <div className="oncology-panel">
          <h1>What Is a Liver Transplant?</h1>
          <div className='separator'></div>
          <p>A liver transplant is a procedure through which a person who has end-stage liver disease and/or acute liver failure receives a new liver from a donor. During surgery, the diseased liver will be removed and replaced with a healthy liver or a segment of a liver from the donor person. A liver transplant is an effective treatment for end-stage liver disease because it is considered to be curative. When you suffer from a liver disorder, it is more than likely that your body has already tried to naturally fight against it.</p>
          <p>However, if the liver disorder keeps progressing and there is no medical treatment that has brought success, then you may qualify for a liver transplant. When a candidate suffers from some of the next liver ailments there is a need for a liver transplant:</p>
          <li className='heading-li'>Hepatitis B</li>
          <li className='heading-li'>Hepatitis C</li>
          <li className='heading-li'>Liver cancers</li>
          <li className='heading-li'>Wilson’s disease</li>
          <li className='heading-li'>Hemochromatosis</li>
          <li className='heading-li'>Acute liver disease</li>
          <li className='heading-li'>Polycystic disease</li>
            <br></br><br></br>
          <h2 className='heading2'>Stages Of Liver Failure</h2>
          <div className='separator'></div>
          <p>The initial stage of liver failure is a mild stressful condition that may last for several months. This involves mainly the accumulation of a certain amount of toxins and waste products in the body. The second stage includes symptoms relating to impaired protein metabolism and decreased levels of vitamins like vitamin K, folic acid and B12. In some patients there is an increase in blood clotting which can lead to bleeding from small cuts or larger bleeds in the intestines.</p>
            <br></br><br></br>
          <h2 className='heading2'>Recommendation For Liver Transplant</h2>
          <p>A liver transplant is recommended when the liver no longer functions adequately to keep a person alive. A successful liver transplant is a life-saving procedure for people with liver failure. Liver failure can happen suddenly, as a result of infection or complications from certain medications, or it can develop slowly over months, years, or decades. Chronic liver failure is usually the result of cholangitis, which is a condition in which healthy liver tissue has been replaced with scar tissue making the liver unable to carry out its normal functions.</p>
            <br></br><br></br>
          <h2 className='heading2'>Benefits Of Liver Transplant</h2>
          <div className='separator'></div>
          <p>A liver transplant procedure may be the best solution for patients with serious complications caused by end-stage liver disease. This surgery can help them maintain an active lifestyle and a longer life. However, one day you may require this surgery as well, especially if your condition worsens.</p>
          <p>Living-donor liver transplantation is a surgical procedure that removes a portion of the liver from a donor, who remains healthy, and replaces the liver with a recipient. Living-donor liver transplantation can save the patients from a long organ waiting list and the surgery can be scheduled as per the convenience of both the patient and donor.</p>
        </div>

        <input
          className="oncology-input"
          name="tabs"
          type="radio"
          id="tab-4"
          checked={activeTab === 'tab-4'}
          onChange={() => handleTabClick('tab-4')}
        />
        <label className="oncology-label" htmlFor="tab-4">Hernia</label>
        <div className="oncology-panel">
          <h1>What Is Hernia?</h1>
          <div className='separator'></div>
          <p>A hernia is a common condition and occurs when an internal organ bulges through a weak area of muscle or tissue. Hernia surgery is needed when the hernia causes problems like pain, swelling, trouble breathing, and/or bowel obstruction. Hernia is a protrusion of tissue or organ from its normal position. It usually happens because of an injury or defect in the abdominal muscles and is also an effect of aging. The condition may not produce any symptoms but may result in severe pain. An emergency may arise when the contents bulge out through the opening which creates pressure on the blood supply to the tissues.</p>
            <br></br><br></br>
          <h2 className='heading2'>Who Should Go For Hernia Surgery?</h2>
          <div className='separator'></div>
          <p>If your hernia is large, it could hamper your daily activities. You may have to restrict yourself from certain movements, due to the discomfort in the area. Moreover, it might put the bowel at risk (you can get trapped in a hernia).</p>
        <br></br><br></br>
        <h2 className='heading2'>Causes Of Hernia</h2>
        <div className='separator'></div>
        <p>Hernias are usually present at birth but can also develop later in life. This can occur when there is increased pressure on the abdominal cavity, which results in a hernia forming. Congenital hernias tend to be more common in men while acquired hernias are more common in women. To reduce hernia risk, you must eat a diet rich in fiber and engage in regular physical activity.</p>
        <br></br><br></br>
        <h2 className='heading2'>Benefits Of Laparoscopic Hernia Repair Surgery</h2>
        <div className='separator'></div>
        <p>A laparoscopic hernia repair surgery is efficient and offers great benefits to patients. A person can live a normal life without a gallbladder. Though the organ can be useful, it is not necessary.</p>
        <p>The liver of a person will still produce bile for digesting the food. By adopting simple lifestyle changes, a person can live a normal life-
        The laparoscopic method involves making only a few small incisions instead of one large incision. This minimizes blood loss and postoperative pain and can allow the patient to return to work quickly.</p>
        </div>

        <input
          className="oncology-input"
          name="tabs"
          type="radio"
          id="tab-5"
          checked={activeTab === 'tab-5'}
          onChange={() => handleTabClick('tab-5')}
        />
        <label className="oncology-label" htmlFor="tab-5">Liver Cirrhosis</label>
        <div className="oncology-panel">
          <h1>What Is The Liver Cirrhosis?</h1>
          <div className='separator'></div>
          <p>Cirrhosis is a disease which happens when a part of the liver gets destroyed. The liver has an important function in the body in that it cleanses the blood from harmful toxins and other harmful chemical substances. Cirrhosis is the condition when a part of liver becomes damaged due to the presence of harmful chemicals or other chemical substances or due to infections like Hepatitis C. Liver cirrhosis occurs because of alcohol overuse, chronic hepatitis B or C, fatty accumulation in liver (steatosis) and some inherited disorders.</p>
          <li className='heading-li'>The functions of the liver include production of cholesterol and breaking down saturated fat, as well as storing glucose in the form of glycogen, manufacturing bile necessary for digestion.</li>
          <li className='heading-li'>Plasma is the liquid part of blood. It is a complex mixture of proteins and other small molecules that help in blood clotting, immune system functions and oxygen transport. It also stores excess nutrients and returns some of these nutrients to the bloodstream.</li>
          <br></br><br></br>
          <h2 className='heading2'>What Are The Causes Of Liver Cirrhosis?</h2>
          <div className='separator'></div>
          <li className='heading-li'>Fatty liver disease can be caused by lifelong obesity and long-term diabetes.</li>
          <li className='heading-li'>Diseases causing loss of appetite are a group of conditions where a person’s appetite is reduced.</li>
          <li className='heading-li'>Liver cirrhosis can be caused by a number of things, including alcoholism, hepatitis, and fatty liver disease.</li>
          <li className='heading-li'>However, if your liver is damaged because of any of these conditions it becomes scarred, which can lead to cirrhosis.</li>
          <li className='heading-li'>The most common cause is anorexia nervosa, but it can also be due to cancer, trauma, neurological disorders, and other illnesses.</li>
          <li className='heading-li'>If the liver begins to accumulate fat, it may be irreversible. If a person suspects that he or she has fatty liver, there are several tests that can help confirm the diagnosis.</li>
            <br></br><br></br>
            <h2 className='heading2'>What Are The Symptoms Of Cirrhosis?</h2>
          <div className='separator'></div>
          <li className='heading-li'>There are different stages of cirrhosis, and each stage brings new symptoms and issues.</li>
          <li className='heading-li'>Cirrhosis is a disease in which the normal structure of the liver is replaced by scar tissue, leading to loss of liver function.</li>
          <li className='heading-li'>The most common symptoms of cirrhosis include jaundice (yellowing of the skin), easy bruising due to decreased production of blood clotting factors by the diseased liver.</li>
          <li className='heading-li'>Swelling in limbs, accumulation of excess fluid in tissues and organs such as abdominal swelling or ascites (fluid collection between the abdomen wall and intestines), itching caused by increased absorption of bilirubin by the body, fatigue, and lethargy.</li>
            <br></br><br></br>
            <h2 className='heading2'>Some Advanced Symptoms Include</h2>
          <div className='separator'></div>
          <li className='heading-li'>Decreased muscle mass and weakness can also result.</li>
          <li className='heading-li'>The advanced symptoms are most likely to occur with Vitamin B12 deficiency.</li>
          <li className='heading-li'>In some cases, people may experience dark urine and accumulation of fluid in the abdomen or legs.</li>
          <li className='heading-li'>They include gastrointestinal bleeding and coma, as well as impaired sleep, difficulty in concentrating and poor memory.
</li>

        </div>
       </div>
    </div>
    <Footer/>
    </>
  );
};

export default Gastro;
