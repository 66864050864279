import React from 'react';
import './OurServiceText.css'; // Import your CSS file for custom styling
import Treatmentimage from '../../images/medsuit-min.jpg';

function OurServiceText() {
  return (
    <div className="text-image-section">
      <div className="text-image-container">
        <img src={Treatmentimage} alt="Image Description" className="service-image" />
      </div>
      <div className="text-image-description">
        <h2>
          What We Do ?
       </h2>
        <p>At ABC Healthcare Solutions, we specialize in facilitating medical assistance for
          Nepali patients across a range of conditions and requirements. Our dedicated team
          is committed to making this process as seamless as possible. Through a variety of
          avenues, we connect patients with the best medical expertise, advanced treatments,
          and state-of-the-art facilities.</p>
      </div>
    </div>
  );
}

export default OurServiceText;
