import React from 'react'
import './TourismText.css'

const TourismText = () => {
  return (
    <>
    <div className='tourism-text-section'>
        <h2>Medical Tourism</h2>
    </div>
    </>
  )
}

export default TourismText
