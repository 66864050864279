import React from 'react'
import Footer from '../../elements/Footer/Footer'
import Scroll from '../../elements/Scroll/Scroll'
import OrganCard from './OrganCard'
import OrganImage from './OrganImage'
import OrganText from './OrganText'


const OrganTransplant = () => {
  return (
    <>

    <OrganImage/>
    <OrganText/>
    <OrganCard/>
    <Scroll/>
    <Footer/>  
    
    </>

  )
}

export default OrganTransplant
