import React from 'react';
import './Aboutimage.css'
import exampleImage from '../../images/aboutus-min.jpg'; // Import your image here

const Aboutimage = () => {
  return (
    <div className='single-image-container'>
      <div className="single-image-section animated-section">
        <img src={exampleImage} alt="Example Image" className='responsive-image' />
      </div>
    </div>
  );
};

export default Aboutimage;
