// Import React and useState from React
import React, { useState } from 'react';
// Import your CSS file for styling
import './InternationalCard.css'; 
// Import images
import apolloImage from '../../images/apollo.jpg';
import blkImage from '../../images/blk.jpg'
import medantaImage from '../../images/medanta.jpg';
import mgmImage from '../../images/mgm.png';
import artemisImage from '../../images/artemis.jpg';
import samitivezImage from '../../images/samitivez.png';
import drrelaImage from '../../images/drrela.jpg';
import sarvoImage from '../../images/sarvodaya.jpg';
import bangImage from '../../images/bangkok.jpg';
import fortisImage from '../../images/fortis.jpg';
import maxImage from '../../images/max.jpg';
import amritaImage from '../../images/amrita.jpg';

// Define the component
const InternationalCard = () => {
  // State to manage expansion of sections
  const [expanded, setExpanded] = useState(false);

  // Function to toggle expansion
  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

  // JSX structure for each hospital section
  return (
    <>
      {/* Apollo Hospital */}
      <div className="internationalcard-section">
        <div className="internationalcard-image">
          <img src={apolloImage} alt="Apollo Hospital" />
        </div>
        <div className="internationalcard-content">
          <h2>Indraprastha Apollo Hospital</h2>
          <h4> New Delhi, India</h4>
          <p>
          Indraprastha Apollo Hospital provides comprehensive care for all the problems related
          to bones and joints, including management of fractures, dislocation, arthritis, sports
          injuries and osteoporosis etc.
          High-end operations like total joint replacements of knees,
          hips etc., arthroscopic (keyhole) surgery of the joints like knee, shoulder, etc. is routinely
          done in our facility with outstanding success rates.
        </p>
        {expanded && (
          <p>
             The hospital also provides the latest
            cutting-edge patient-specific jigs for total knee replacement surgery. Apollo Hospitals
            has touched more than 120 million lives from over 120 countries, offering the most
            effective clinical outcomes.
          </p>
         )}
          <button className="toggle-button" onClick={handleToggleExpand}>
            {expanded ? 'Show Less' : 'Show More'}
          </button>
        </div>
      </div>

      {/* Medanta - The Medicity */}
    <div className="internationalcard-section">
        <div className="internationalcard-image">
          <img src={medantaImage} alt="Medanta - The Medicity" />
        </div>
        <div className="internationalcard-content">
          <h2>Medanta - The Medicity</h2>
          <h4>Gurugram, Haryana</h4>
          <p>
            Medanta – The Medicity is one of India’s largest multi-super speciality institutes located
            in Gurugram. Founded by eminent cardiac surgeon, Dr. Naresh Trehan, the institution
            has been envisioned with the aim of bringing to India the highest standards of medical
            care along with clinical research, education and training. 
          </p>
          {expanded &&(
             <p> Medanta is governed under the
             guiding principles of providing medical services to patients with care, compassion,
             commitment. The hospital has 800+ experienced doctors, 1250 beds facility and 29
             super specialities.</p>
          )}
          <button className="toggle-button" onClick={handleToggleExpand}>
            {expanded ? 'Show Less' : 'Show More'}
          </button>
        </div>
      </div>

      {/* MGM Healthcare Hospital */}
    <div className="internationalcard-section">
        <div className="internationalcard-image">
          <img src={mgmImage} alt="MGM Healthcare Hospital" />
        </div>
        <div className="internationalcard-content">
          <h2>MGM Healthcare Hospital</h2>
          <h4>Chennai, Tamil Nadu</h4>
          <p>
            A state-of-the-art, JCI, NABH and LEED Platinum certified, 400-bed quaternary care multi-
            speciality hospital in Chennai, India, MGM Healthcare is committed to improving lives through
            outstanding healthcare. 
          </p>
          {expanded && (

            <p>This hospital aspires to deliver outstanding clinical outcomes and
            patient experiences with world-class expertise backed by next-generation medical and digital
            technologies. Their vision is to create a complete healthcare ecosystem for personalized
            medical care.</p>
          )}
          <button className="toggle-button" onClick={handleToggleExpand}>
            {expanded ? 'Show Less' : 'Show More'}
          </button>
        </div>
      </div>

      {/* Artemis Hospital */}
     <div className="internationalcard-section">
        <div className="internationalcard-image">
          <img src={artemisImage} alt="Artemis Hospital" />
        </div>
        <div className="internationalcard-content">
          <h2>Artemis Hospital</h2>
          <h4>Gurugram, Haryana</h4>
          <p className={expanded ? 'expanded' : ''}>
            Artemis is the first hospital in Gurgaon to be accredited by JCI and NABH. Designed as
            one of the most advanced in India, Artemis provides in-depth expertise in the sphere of
            advanced medical &amp; surgical interventions, a comprehensive mix of inpatient and
            outpatient services. 
          </p>
          { expanded && (
            <p>Artemis aims to provide excellent medical care in a compassionate
            environment and a practice dedicated to pioneering research, empowering the health
            care professionals and the diverse communities we serve. The clinical procedures
            followed in the hospital are technologically advanced and supervised by distinguished
            doctors of international repute.</p>
          )}
          <button className="toggle-button" onClick={handleToggleExpand}>
            {expanded ? 'Show Less' : 'Show More'}
          </button>
        </div>
      </div>


      <div className="internationalcard-section">
        <div className="internationalcard-image">
          <img src={amritaImage} alt="Artemis Hospital" />
        </div>
        <div className="internationalcard-content">
          <h2>Amrita Institute of Medical Sciences</h2>
          <h4>Faridabad, India</h4>
          <p className={expanded ? 'expanded' : ''}>
          Amrita Hospital in Faridabad is a subsidiary of Amrita Institute of Medical Sciences, one
          of the leading healthcare providers in India. It offers a wide range of medical services
          and facilities, including diagnostic and therapeutic services, inpatient and outpatient
          care, and specialist clinics.
          </p>
          { expanded && (
            <p>The hospital is equipped with state-of-the-art technology and
            staffed by highly trained medical professionals, providing high-quality medical care to
            
            patients. Some of the specialties offered at the Faridabad location include cardiology,
            oncology, gastroenterology, neurology, orthopedics, and pulmonology, among others.
            Spread across 130 acres, the center has 2600 beds, 534 digitally connected ICU beds,
            64 fully networked modular operation theatres, 300 Paediatric super-specialty beds, and
            fully automated smart laboratories. The hospital is committed to providing quality patient
            care and is dedicated to improving the health and well-being of the community.</p>
          )}
          <button className="toggle-button" onClick={handleToggleExpand}>
            {expanded ? 'Show Less' : 'Show More'}
          </button>
        </div>
      </div>

      {/* Samitivej Hospital */}
     <div className="internationalcard-section">
        <div className="internationalcard-image">
          <img src={samitivezImage} alt="Samitivej Hospital" />
        </div>
        <div className="internationalcard-content">
          <h2>Samitivej Hospital</h2>
          <h4>Bangkok, Thailand</h4>
          <p className={expanded ? 'expanded' : ''}>
            Samitivej Hospital has a long history of excellent care and innovation.
            In 1999, Samitivej became the first hospital in Thailand to be awarded
            the prestigious recognition as a Mother-and-Baby Friendly Hospital by
            the WHO and UNICEF.
          </p>
          {expanded && (
            <p> Further recognitions include Thailand’s Prime
            Minister Award for the Most Recognized Service in 2004 and hospital
            accreditation by the Joint Commission International (JCI). Samitivej
            was also the first hospital outside of Japan to be accredited with the
            Japan Council for Quality Health Care (JCQHC) award. Samitivej
            Hospital was named the best hospital in Asia for medical tourists by
            the Medical Travel Quality Alliance (MTQUA), ranking among the
            world’s top 10 hospitals for medical travelers.</p>
          )}
          <button className="toggle-button" onClick={handleToggleExpand}>
            {expanded ? 'Show Less' : 'Show More'}
          </button>
        </div>
      </div>

      {/* Dr Rela Institute Medical Center */}
     <div className="internationalcard-section">
        <div className="internationalcard-image">
          <img src={drrelaImage} alt="Dr Rela Institute Medical Center" />
        </div>
        <div className="internationalcard-content">
          <h2>Dr Rela Institute Medical Center</h2>
          <h4>Chennai, Tamil Nadu</h4>
          <p className={expanded ? 'expanded' : ''}>
            Rela Hospital, an International Medical facility, is a quaternary care hospital dedicated to
            fostering and responding to the needs of a diverse patient population. The hospital provides
            highest quality healthcare in India, as well as access to cutting-edge technology and experienced,
            caring medical professionals.
          </p>
          {expanded && (
            <p> The hospital is committed to being an internationally significant
            health care system with the state-of-the art infrastructure facilities. Rela Hospital is designed to
            provide highly specialised care in all specialties with special focus on care of the critically ill and
            multi-organ transplantation patients. Established under the formidable leadership of Prof.
            Mohamed Rela, a world-renowned surgeon in the field of Liver Surgery and Transplantation,
            this hospital houses one of the world’s largest dedicated liver intensive care units.</p>
          )}
          <button className="toggle-button" onClick={handleToggleExpand}>
            {expanded ? 'Show Less' : 'Show More'}
          </button>
        </div>
      </div>

      {/* Bangkok Hospital */}
      <div className="internationalcard-section">
        <div className="internationalcard-image">
          <img src={bangImage} alt="Bangkok Hospital" />
        </div>
        <div className="internationalcard-content">
          <h2>Bangkok Hospital</h2>
          <h4>Bangkok, Thailand</h4>
          <p className={expanded ? 'expanded' : ''}>
            Bangkok Hospital was established in 1972 as one of the first private hospitals in
            Thailand. Over the past 50 years we have expanded our operations to become a
            tertiary care facility with dedicated hospitals for cancer and cardiology.
          </p>
          <button className="toggle-button" onClick={handleToggleExpand}>
            {expanded ? 'Show Less' : 'Show More'}
          </button>
        </div>
      </div>

      {/* Fortis Memorial Research Institute */}
     <div className="internationalcard-section">
        <div className="internationalcard-image">
          <img src={fortisImage} alt="Fortis Memorial Research Institute" />
        </div>
        <div className="internationalcard-content">
          <h2>Fortis Memorial Research Institute</h2>
          <h4>Gurugram, Haryana</h4>
          <p className={expanded ? 'expanded' : ''}>
            Fortis Memorial Research Institute (FMRI), Gurugram, is a multi-super speciality,
            quaternary care hospital with an enviable international faculty, reputed clinicians,
            including super-sub-specialists and speciality nurses, supported by cutting-edge
            technology.
          </p>
          {expanded && (
            <p> The center specializes in Robotic Surgery, Neurosciences, Oncology, Renal
            Sciences, Body Modification Therapy, Organ Transplants, Orthopedics, Cardiology, and
            Obstetrics &amp; Gynecology. Set on a spacious 11-acre campus with a potential to grow to
            1000 beds, this ‘Next Generation Hospital’ is built on the foundation of ‘Trust’ and rests
            on the four strong pillars of Talent, Technology, Infrastructure and Service.</p>
          )}
          <button className="toggle-button" onClick={handleToggleExpand}>
            {expanded ? 'Show Less' : 'Show More'}
          </button>
        </div>
      </div>

      <div className="internationalcard-section">
        <div className="internationalcard-image">
          <img src={blkImage} alt="Fortis Memorial Research Institute" />
        </div>
        <div className="internationalcard-content">
          <h2>BLK-Max Super Speciality Hospital</h2>
          <h4>Rajendra Place, New Delhi</h4>
          <p className={expanded ? 'expanded' : ''}>
          BLK-Max Super Speciality Hospital is part of the largest healthcare networks in India,
          offering a wide range of services, making it a force to reckon within the field of Super
          Speciality Tertiary Healthcare. The Hospital has a capacity of 650 beds with dedicated
          125 critical care beds, 17 modular operation theatres and speciality-specific dedicated
          OPD blocks.
          </p>
          {expanded && (
            <p> The facility is equipped with the most modern medical diagnostic & 
            therapeutic equipment. BLK-Max’s Advanced Centres of Excellence, state-of-the-art
            facilities and patient-centric services provide the necessary backbone for holistic,
            comprehensive and contemporary treatment plan.</p>
          )}
          <button className="toggle-button" onClick={handleToggleExpand}>
            {expanded ? 'Show Less' : 'Show More'}
          </button>
        </div>
      </div>


      <div className="internationalcard-section">
        <div className="internationalcard-image">
          <img src={maxImage} alt="Fortis Memorial Research Institute" />
        </div>
        <div className="internationalcard-content">
          <h2>Max Super Speciality Hospital, Saket</h2>
          <h4>Saket, New Delhi</h4>
          <p className={expanded ? 'expanded' : ''}>
          Located in the heart of south Delhi, the 530+ bed Max Super Speciality Hospital, Saket,
          is widely considered as one of the best hospitals in the country. It has a complete
          spectrum of diagnostic and therapeutic technologies, including several which are a First
          in India and Asia. Max Super Speciality Hospital, Saket has received NABH & JCI
          accreditation for providing the highest quality of patient safety and care.
          </p>
          {expanded && (
            <p> It offers the
            advantage of integrated medical care in a multidisciplinary setting provided by a faculty
            of highly qualified doctors, nurses, & healthcare professionals. Max Super Speciality
            Hospital, Saket, is a regional hub for complex procedures such as neurovascular
            intervention, targeted cancer treatments, heart surgeries, orthopedic surgeries, liver &
            kidney transplant, and fertility treatments..</p>
          )}
          <button className="toggle-button" onClick={handleToggleExpand}>
            {expanded ? 'Show Less' : 'Show More'}
          </button>
        </div>
      </div>

      {/* Sarvodaya Healthcare */}
    <div className="internationalcard-section">
        <div className="internationalcard-image">
          <img src={sarvoImage} alt="Sarvodaya Healthcare" />
        </div>
        <div className="internationalcard-content">
          <h2>Sarvodaya Healthcare</h2>
          <h4>Guwahati, India</h4>
          <p className={expanded ? 'expanded' : ''}>
            Sarvodaya Healthcare is a pioneering healthcare group, serving the community for over the last
            32 years with affordable and top-class medical facilities and compassionate care. We embarked
            on the journey 32 years ago with the mission, Sarve Santu Niramaya: Good health for all -
            irrespective of background or ailment; and the vision of providing affordable and top-class
            medical facilities.

            The trust equity of our patients and their families has helped us in becoming a pioneering
            healthcare hub, starting from a 5-bedded clinic to an 800-bedded healthcare group that includes
            tertiary &amp; secondary care hospitals, nursing &amp; paramedic institute, health clinics, diagnostic &amp;
            imaging centres and dialysis centres across Faridabad, Greater Noida, Delhi and other cities in
            the National Capital Region.

            
          </p>
          {expanded &&(
            <p>We leverage cutting-edge technology along with a team of skilled specialists to provide
            advanced treatment under super specialities like Cancer Care &amp; Bone Marrow transplants,

            Nuclear Medicine and Theranostics, Radiotherapy, Orthopaedics &amp; Robotic Joint Replacement,
            Neurosciences, Paediatric to Adult Cardiology &amp; Cardiac Surgeries, Dialysis &amp; Kidney
            Transplant, Minimal Access Surgery, Gastroenterology &amp; GI Surgeries, Urology &amp; LASER
            Urological Surgeries, ENT &amp; Cochlear Implant, Diabetes &amp; Endocrinology, Plastic &amp;
            Reconstructive Surgery, Rheumatology, Pulmonology etc.</p>
          )}
          <button className="toggle-button" onClick={handleToggleExpand}>
            {expanded ? 'Show Less' : 'Show More'}
          </button> 
        </div>
      </div>
    </>
  );
};

// Export the component
export default InternationalCard;
