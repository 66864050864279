import React from 'react'
import Footer from '../../elements/Footer/Footer'
import Counting from '../../elements/CountingNum/Counting'
import WeCare from '../../elements/WeCare/WeCare'
import Testimonial from '../../elements/Testimonial/Testimonial'
import Service from '../../elements/Service/Service'
import Info from '../../elements/Info/Info'
import HomeSlider from '../../elements/Slider/HomeSlider'
import Scroll from '../../elements/Scroll/Scroll'
import LocalPartners from '../../elements/Partners/LocalPartners'
import CreateForm from '../../elements/Form/CreateForm'
import Blog from '../../elements/Blog/Blog'


const Home = () => {
  return (
    <>
    <HomeSlider/>
    <Counting/>
    <WeCare/>
    <Testimonial/>
    <Info/>
    <LocalPartners/>
    <CreateForm/>
    <Scroll/>
    <Footer/>
      
    </>
  )
}

export default Home
