// ImageCardSection.js
import React from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import './LocalPartners.css';
import local1 from '../../images/norvic.jpg';
import local2 from '../../images/mediciti.jpg';
import local3 from '../../images/grande.jpeg';
import local4 from '../../images/star.jpg';
import local5 from '../../images/om.jpg';
import international1 from '../../images/bangkok.jpg';
import international2 from '../../images/drrela.jpg';
import international3 from '../../images/artemis.jpg';
import international4 from '../../images/bumrung.jpg';
import international5 from '../../images/samitivez.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';



const imageData = [
  {
    title: 'Norvic Hospital',
    imageUrl: local1
  },
  {
    title: 'Mediciti Hospital',
    imageUrl: local2
  },
  {
    title: 'Grande Hospital',
    imageUrl: local3
  },
  {
    title: 'Star Hospital',
    imageUrl: local4
  },
  {
    title: 'OM Hospital',
    imageUrl: local5
  },
  {
    title: 'Bangkok Hospital',
    imageUrl: international1
  },
  {
    title: 'Dr. Rela Institute & Medical Center',
    imageUrl: international2
  },
  {
    title: 'Artemis Hospital',
    imageUrl: international3
  },
  {
    title: 'BumrungGrad Hospital',
    imageUrl: international4
  },
  {
    title: 'Samitivez Hospital',
    imageUrl: international5
  },
 
  // Add more image data as needed
];

const LocalPartners = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    autoplay: true, 
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      }
    ]
  };

  return (
    
    <div className="local-slider-container"> 
        <h2>Leading HealthCare Providers Around the Globe</h2>
      <Slider {...settings}>
        {imageData.map((image, index) => (
          <div key={index} className="localpart-image-card">
            <img src={image.imageUrl} alt={image.title} className="localpart-card-image" />
            <h3 className="localpart-card-title">{image.title}</h3>
          </div>
        ))}
      </Slider>
      <Link to="/hospitals/local" className="view-more-button">View More</Link>
    </div>
  );
};

const CustomPrevArrow = (props) => (
  <div {...props} className="slick-arrow arrow-slick-prev">
      <FontAwesomeIcon icon={faAngleLeft} className="arrow-icon" />
  </div>
);

const CustomNextArrow = (props) => (
  <div {...props} className="slick-arrow arrow-slick-next">
          <FontAwesomeIcon icon={faAngleRight} className="arrow-icon" />

  </div>
  
);


export default LocalPartners;
