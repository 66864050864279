import logo from './logo.svg';
import './App.css';
import { useEffect } from 'react'; 
import Footer from './Components/elements/Footer/Footer';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Navbar from './Components/elements/Navbar/Navbar';
import Home from './Components/pages/Home/Home';
import About from './Components/pages/About/About';
import Local from './Components/pages/Hospital/Local';
import International from './Components/pages/Hospital/International';
import OurService from './Components/pages/OurService/OurService';
import OrganTransplant from './Components/pages/Treatment/OrganTransplant';
import Procedure from './Components/pages/Treatment/Procedure';
import OurContact from './Components/pages/OurContact/OurContact';
import Tourism from './Components/pages/Tourism/Tourism';
import Oncology from './Components/pages/Treatment/TreatmentSection/Oncology';
import Hematology from './Components/pages/Treatment/TreatmentSection/Hematology';
import Trransplant from './Components/pages/Treatment/TreatmentSection/Trransplant';
import Ent from './Components/pages/Treatment/TreatmentSection/Ent';
import Pediatric from './Components/pages/Treatment/TreatmentSection/Pediatric';
import Nephrology from './Components/pages/Treatment/TreatmentSection/Nephrology';
import Spine from './Components/pages/Treatment/TreatmentSection/Spine';
import Cosmetic from './Components/pages/Treatment/TreatmentSection/Cosmetic';
import Bariatric from './Components/pages/Treatment/TreatmentSection/Bariatric';
import Gastro from './Components/pages/Treatment/TreatmentSection/Gastro';
import Gyno from './Components/pages/Treatment/TreatmentSection/Gyno';
import Cardio from './Components/pages/Treatment/TreatmentSection/Cardio';
import Orthopaedic from './Components/pages/Treatment/TreatmentSection/Orthopaedic';
import { useState } from 'react';
import Modal from './Components/elements/Modal/Modal';



function App() {
    const [data, setData] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(true);



    useEffect(() => {
      setTimeout(() => {
        setData(true);
        setLoading(false)
       } , 200);
    }, [])

    
    const closeModal = () =>{
      setShowModal(false);
    };  


  return (
    <>  

    <div>
         {/*{loading && <Loader/>} */}
      { data && (
        <div>
    <BrowserRouter>
      <Navbar/>
      <Routes>
        <Route path='/' exact element={<Home/>}/>
        <Route path='/about' element={<About/>}/>
        <Route path='/ourservice' element={<OurService/>}/>
        <Route path='/hospitals/local' element={<Local/>}/>
        <Route path='/hospitals/international' element={<International/>}/>
        <Route path='/treatments/organ' element={<OrganTransplant/>} />
        <Route path='/treatments/procedure' element={<Procedure/>} />
        <Route path='/medicaltourism' element={<Tourism/>}/>
        <Route path='/contact' element={<OurContact/>}/>
        <Route path='/treatments/oncology' element={<Oncology/>}/>
        <Route path='/treatments/hematology' element={<Hematology/>}/>
        <Route path='/treatments/transplant' element={<Trransplant/>}/>
        <Route path='/treatments/nephrology' element={<Nephrology/>}/>
        <Route path='/treatments/spine' element={<Spine/>}/>
        <Route path='/treatments/cosmetic' element={<Cosmetic/>}/>
        <Route path='/treatments/pediatric' element={<Pediatric/>}/>
        <Route path='/treatments/bariatric' element={<Bariatric/>}/>
        <Route path='/treatments/gastro' element={<Gastro/>}/>
        <Route path='/treatments/gynae' element={<Gyno/>}/>
        <Route path='/treatments/cardio' element={<Cardio/>}/>
        <Route path='/treatments/orthopaedic' element={<Orthopaedic/>}/>
        <Route path='/treatments/ent' element={<Ent/>}/>

      </Routes>
    </BrowserRouter>

  {/*  {showModal && <Modal closeModal={closeModal} />} */}

    </div>

    )}
    </div>
    </>
  );
}

export default App;
