import React from "react";
import './Scroll.css'


function Scroll() {
    const calcScrollValue = () => {
      const scrollProgress = document.getElementById('progress');
      const progressValue = document.getElementById('progress-value');
      const pos = document.documentElement.scrollTop;
      const calcHeight =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      const scrollValue = Math.round((pos * 100) / calcHeight);
  
      if (scrollProgress) {
        if (pos > 100) {
          scrollProgress.style.display = 'grid';
        } else {
          scrollProgress.style.display = 'none';
        }
  
        scrollProgress.addEventListener('click', () => {
          document.documentElement.scrollTop = 0;
        });
  
        scrollProgress.style.background = `conic-gradient(#fff ${scrollValue}%, #1A76D1 ${scrollValue}%)`;
      }
    };
  
    window.onscroll = calcScrollValue;
    window.onload = calcScrollValue;
  
    return (
      <>
        <div id="progress">
          <span id="progress-value">⬇</span>
        </div>
      </>
    );
  }
  
  export default Scroll;
  