import React, { useState } from 'react';
import { Container, Row, Col, Modal, Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHelicopter,
  faMobileAlt,
  faUsers,
  faHandsHelping,
  faCashRegister,
  faHouseMedical,
  faPlaneDeparture,
  faHeart,
  faMoneyBill,
} from '@fortawesome/free-solid-svg-icons';
import './Service.css'; // Import CSS file

const services = [
  {
    icon: faHelicopter,
    title: 'Air Ambulance Facility',
    description:
      'At ABC Healthcare Solutions, we understand that medical emergencies can arise anywhere, even in the air. Our Air Ambulance Service with a dedicated medical team ensures that critical patients receive the highest level of care while being transported swiftly to medical facilities.',
  },
  {
    icon: faMobileAlt,
    title: 'Coordination of all medical appointments',
    description:
      'At ABC Healthcare Solutions, we understand the complexity of managing medical appointments, consultations, and treatments. That is why we offer comprehensive coordination services to ensure that your healthcare journey is as smooth and stress-free as possible.',
  },
  {
    icon: faHeart,
    title: 'Health check appointments',
    description:
      'At ABC Healthcare Solutions, your well-being is our top priority. Our Health Check Appointment service facilitates comprehensive health assessments, tailored to your individual needs and medical history. We connect you with experienced healthcare professionals who specialize in preventive care, allowing you to take control of your health.',
  },
  {
    icon: faMoneyBill,
    title: 'Cost Estimate',
    description:
      'At ABC Healthcare Solutions, we understand that healthcare costs can be a concern. Our Cost Estimate service is designed to provide you with transparent and accurate information about the expenses associated with your medical treatments and procedures.',
  },
  {
    icon: faUsers,
    title: 'Travel Assistance',
    description:
      'Our Travel Assistance service is designed to take care of the logistical aspects of your healthcare journey. Whether it is coordinating flights, accommodations, or transportation, we are here to make your travel experience as smooth as possible.',
  },
  {
    icon: faPlaneDeparture,
    title: 'Airport Transfer',
    description:
      'Our International Staff & Transfer Facilities service brings together a team of international medical professionals who understand the needs of patients from different countries. We provide a holistic approach to care and facilitate smooth transfers between medical facilities, ensuring continuity in treatment.',
  },
  {
    icon: faHandsHelping,
    title: 'Accommodation assistance for patient and Companion',
    description:
      'At ABC Healthcare Solutions, we understand that medical journeys often require a supportive and comfortable environment. Our Accommodation Assistance service is designed to ensure that both patients and their companions have a suitable place to stay during their healthcare journey.',
  },
  {
    icon: faHouseMedical,
    title: 'Telemedicine consultation',
    description:
      'Through Telemedicine Consultations, we connect you with experienced healthcare professionals who can provide medical evaluations, advice, and recommendations remotely. This service ensures that you receive timely and personalized care, even if you are unable to visit a physical healthcare facility.',
  },
];

const Service = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    number: '',
    message: ''
  });

  const handleServiceClick = (service) => {
    setSelectedService(service);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedService(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    console.log(formData);
    // You can perform form submission logic here, e.g., sending data to server
    handleCloseModal();
  };

  return (
    <section id="services">
      <Container>
        <h2 className="text-center mb-5 colored-heading">Services Offered By Us</h2>
        <Row>
          {services.map((service, index) => (
            <Col key={index} xs={12} md={6} lg={3} className="service-item">
              <div>
                <div className="left-content">
                  <FontAwesomeIcon className="service-icon" icon={service.icon} />
                </div>
                <div className="right-content">
                  <h3 className="service-title">{service.title}</h3>
                </div>
              </div>
              <Button variant="primary" className="learn-more-button" onClick={() => handleServiceClick(service)}>
                Learn More
              </Button>
            </Col>
          ))}
        </Row>
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>{selectedService && selectedService.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedService && selectedService.description}
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formName">
                <Form.Label>Patient Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formNumber">
                <Form.Label>Contact Number</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter Contact"
                  name="number"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formMessage">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter your message"
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </section>
  );
};

export default Service;
