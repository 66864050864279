import React from 'react'
import './LocalText.css'

const LocalText = () => {
  return (
    <>
      <div className='local-text-section'>
        <h2>National Hospitals</h2>
      </div>
    </>
  )
}

export default LocalText
