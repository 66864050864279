import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faUserMd, faGlobe, faLightbulb, faCompass, faHandsHelping, faMapMarkedAlt, faStar } from '@fortawesome/free-solid-svg-icons';
import './Objectives.css';
import { Parallax } from 'react-parallax';
import pic from '../../images/med.jpg';

function Objectives() {
  return (
    <Parallax strength={400} bgImage={pic}>
      <div className="container py-5 ">
        <h2 className="text-center mb-4 objectives-heading">Objectives of ABC Healthcare Solutions</h2>
        <div className="objective-card-carousel">
          <div className="objective-card">
            <div className="objective-card-body">
              <h3 className="objective-card-title">
                <FontAwesomeIcon icon={faHeart} className="objectives-icon" />
                Comprehensive Guidance and Support
              </h3>
              <p className="objective-card-text">ABC Healthcare Solution aims to provide patients with comprehensive guidance and support throughout their entire hospitalization journey, starting from the initial consultation.</p>
            </div>
          </div>
          <div className="objective-card">
            <div className="objective-card-body">
              <h3 className="objective-card-title">
                <FontAwesomeIcon icon={faUserMd} className="objectives-icon" />
                Collaboration with Renowned Doctors and Hospitals
              </h3>
              <p className="objective-card-text">The company strives to collaborate closely with renowned doctors and top-tier hospitals to ensure that clients receive the highest quality care available.</p>
            </div>
          </div>
          <div className="objective-card">
            <div className="objective-card-body">
              <h3 className="objective-card-title">
                <FontAwesomeIcon icon={faLightbulb} className="objectives-icon" />
                Empowerment through Knowledge
              </h3>
              <p className="objective-card-text">One of the primary objectives is to empower individuals with the knowledge and resources necessary to make informed healthcare decisions.</p>
            </div>
          </div>
          {/* Add 5 more cards */}
          <div className="objective-card">
            <div className="objective-card-body">
              <h3 className="objective-card-title">
                <FontAwesomeIcon icon={faGlobe} className="objectives-icon" />
                Global Access to Healthcare
              </h3>
              <p className="objective-card-text">Providing access to healthcare services globally, ensuring everyone has access to quality healthcare regardless of geographical location.</p>
            </div>
          </div>
          <div className="objective-card">
            <div className="objective-card-body">
              <h3 className="objective-card-title">
                <FontAwesomeIcon icon={faCompass} className="objectives-icon" />
                Compassionate Care
              </h3>
              <p className="objective-card-text">Delivering compassionate care to every patient, prioritizing their well-being and comfort throughout their healthcare journey.</p>
            </div>
          </div>
          <div className="objective-card">
            <div className="objective-card-body">
              <h3 className="objective-card-title">
                <FontAwesomeIcon icon={faHandsHelping} className="objectives-icon" />
                Support for Families
              </h3>
              <p className="objective-card-text">Offering support and guidance to families of patients, ensuring they are informed and involved in the healthcare process.</p>
            </div>
          </div>
          <div className="objective-card">
            <div className="objective-card-body">
              <h3 className="objective-card-title">
                <FontAwesomeIcon icon={faMapMarkedAlt} className="objectives-icon" />
                Accessible Healthcare Solutions
              </h3>
              <p className="objective-card-text">Providing accessible healthcare solutions to individuals of all backgrounds, addressing diverse healthcare needs and challenges.</p>
            </div>
          </div>
          <div className="objective-card">
            <div className="objective-card-body">
              <h3 className="objective-card-title">
                <FontAwesomeIcon icon={faStar} className="objectives-icon" />
                Excellence in Patient Care
              </h3>
              <p className="objective-card-text">Striving for excellence in patient care, ensuring the highest standards of quality and safety in every aspect of healthcare delivery.</p>
            </div>
          </div>
        </div>
      </div>
    </Parallax>
  );
}

export default Objectives;
