import React from 'react';
import './AboutHeroSection.css';
import aboutimage from '../../images/abchealthcarelogo.jpg'

function AboutHeroSection() {
  return (
    <div className="about-hero-section">
      
      <div className="about-hero-content">
      <h1>ABOUT ABC HEALTHCARE SOLUTIONS</h1>
        
        <p>
          ABC Healthcare Solution is a premier medical assistance company dedicated to
          providing comprehensive guidance and support to patients from their initial
          consultation to every step of the hospitalization journey. As a trusted healthcare
          concierge service, we collaborate closely with renowned doctors and top-tier
          hospitals to ensure that our clients receive the highest quality care available.
        </p>
        <p>
          Our mission is to empower individuals with the knowledge and resources they
          need to make informed healthcare decisions. Whether you're seeking treatment
          locally or considering options abroad, we leverage our extensive network of
          globally recognized and internationally accredited healthcare providers to connect
          you with the best possible solutions tailored to your unique needs.
        </p>
        <p>
          At ABC Healthcare Solution, we understand the importance of navigating the
          complex landscape of medical care with confidence and ease. Our dedicated team
          of professionals is committed to delivering personalized assistance and support
          throughout every stage of your healthcare journey. From facilitating appointments
          to coordinating travel arrangements and providing ongoing guidance, we are here
          to ensure that you receive seamless, compassionate care every step of the way.
        </p>
        <p>
          With ABC Healthcare Solution by your side, you can rest assured that you are
          receiving expert guidance and access to world-class healthcare services, both
          within Nepal and beyond. Experience peace of mind knowing that you have a
          trusted partner advocating for your health and well-being, every step of the way.
        </p>
      </div>
      {/* Add your image here */}
      <div className="about-hero-image">
        <img src={aboutimage} alt="About ABC Healthcare Solutions" />
      </div>
    </div>
  );
}

export default AboutHeroSection;
