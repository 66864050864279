import React from 'react'
import organImage from '../../images/surgeon.jpg'
import './OrganImage.css'

const OrganImage = () => {
  return (
    <>
     <div className="organ-image-section  animated-section">
      <img src={organImage} alt="Example Image" className='organ-image' />
    </div>
      
    </>
  )
}

export default OrganImage
