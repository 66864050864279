import React from 'react'
import internationalImage from '../../images/hospitalback-min.jpg'
import './InternationalImage.css'

const InternationalImage = () => {
  return (
    <>
     <div className="international-image-section  animated-section">
      <img src={internationalImage} alt="Example Image" className='international-image' />
    </div>
      
    </>
  )
}

export default InternationalImage
