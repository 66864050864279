import React, { useState } from 'react';
import './Oncology.css';
import Footer from '../../../elements/Footer/Footer';
import OrganImage from '../OrganImage';

const Bariatric = () => {
  const [activeTab, setActiveTab] = useState('tab-1');

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <>
    <OrganImage/>
    <div className='oncology-container'>
      <div className="oncology-tabs">
        <input
          className="oncology-input"
          name="tabs"
          type="radio"
          id="tab-1"
          checked={activeTab === 'tab-1'}
          onChange={() => handleTabClick('tab-1')}
        />
        <label className="oncology-label" htmlFor="tab-1">Weight Loss</label>
        <div className="oncology-panel">
          <h1>What Is Weight Loss Surgery?</h1>
          <div className='separator'></div>
          <p>Weight-loss surgery is another name for Bariatric surgery. There are numerous surgical procedures, and all of them help you to lose weight by reducing the amount of food you may eat. You may also be limited in the number of nutrients you can absorb as a result of some operations. The form and function of your digestive system are altered after weight-loss surgery. This procedure may help you reduce weight and manage obesity-related medical issues such as diabetes, obstructive sleep apnea and risk factors for heart disease and stroke are among these disorders.</p>
          <br></br><br></br>
          <h2 className='heading2'>How Can Weight-Loss Surgery Be Defined?</h2>
          <div className='separator'></div>
          <p>Even if the candidate who is interested in the treatment is overweight or obese, weight loss surgery is not allowed for everyone. There are a set of other specific requirements that the patient must meet before undergoing the treatment, and the doctor verifies these requirements before determining which weight reduction surgery he needs to execute. Weight loss surgery is typically suggested for those who:</p>
          <li className='heading-li'>Have a BMI (body mass index) that is greater than 40.</li>
          <li className='heading-li'>Have tried Dieting, exercising, going to the gym, and running and have failed in losing weight.</li>
          <li className='heading-li'>BMI is lower, yet he or she has one or more health concerns. Type 2 diabetes, heart disease, hypertension, sleep apnea, and excessive cholesterol are all symptoms.</li>
        
          <p>Excess weight can cause a lot of issues. it not only lowers a person's self-esteem, but it also has the capability to raise the risk of a variety of other life-threatening conditions, including heart attacks, sleep apnea, stroke, cancer, and hypertension.</p>
          <p>However, the doctor is the one who gets to determine whether or not you are a capable candidate for weight loss surgery based on a set of criteria.</p>
        </div>

        <input
          className="oncology-input"
          name="tabs"
          type="radio"
          id="tab-2"
          checked={activeTab === 'tab-2'}
          onChange={() => handleTabClick('tab-2')}
        />
        <label className="oncology-label" htmlFor="tab-2">Gastric Sleeve</label>
        <div className="oncology-panel">
          <h1>What Is Kidney/Renal Transplant?</h1>
          <div className='separator'></div>
          <p>Kidney failure is life-threatening and a condition that needs to be treated quickly. It might take months or years before the kidneys completely stop functioning, and during this period patients need prompt medical care to prevent further damage and complications.</p>
          <p>Kidney transplant involves a surgical procedure in which the diseased kidney is removed and replaced with a healthy kidney. This can be performed either by replanting a donor's (living or deceased) organ in place of the patient''s kidney, or by implanting a new kidney into the recipient. Kidney transplantation has been called one of the biggest success stories in medical history and has played an important role in extending the lives of millions of individuals around the world.</p>
         <br></br><br></br>
         <h2 className='heading2'>Kidney Donation</h2>
         <div className='separator'></div>
         <li className='heading-li'>Living kidney donation is a lifesaving alternative to waiting for a deceased-donor kidney to become available. With a paired donation, a living donor can save another's life by sharing one of their kidneys via a transplantation surgery.</li>
         <li className='heading-li'>Your family is the most likely to be compatible living kidney donors. So if you are in need of a transplant, it's important to do your research and talk with your doctor about this option. Healthier living donors can save both lives and money by donating a kidney that would otherwise be discarded or kept in storage for someone else who might need it later.</li>
         <li className='heading-li'>Paired donation is another type of living kidney donation. Your donor will be matched with another individual who has a compatible organ and may not need surgery. This is done so that you can receive a kidney from your donor, then use it to receive a compatible kidney from someone else's donor.</li>
         <li className='heading-li'>For example, donor A may not have been aware that she could be the donor to give a kidney to another person. When she dies and the kidney can no longer be used by its intended recipient, a living relative of the deceased donor is often able to donate that kidney to someone else.</li>
         <li className='heading-li'>Put your name on the waiting list if you qualify based on medical history, blood type and other criteria. If a deceased-donor kidney becomes available due to the death of another willing donor or a transplant patient needing a kidney for emergency surgery, your name will be given priority.</li>
        </div>

        <input
          className="oncology-input"
          name="tabs"
          type="radio"
          id="tab-3"
          checked={activeTab === 'tab-3'}
          onChange={() => handleTabClick('tab-3')}
        />
        <label className="oncology-label" htmlFor="tab-3">Gastric Bypass</label>
        <div className="oncology-panel">
          <h1>What Is Gastric Bypass Surgery?</h1>
          <div className='separator'></div>
          <p>The upper section of the stomach is used to create a tiny pouch during the gastric bypass treatment. By linking the pouch produced to the middle part of the small intestine, the rest of the stomach is bypassed. As a result, the food consumed by the patient after surgery enters the intestine. Because the stomach is smaller, the patient experiences an early sense of fullness and eats far less food than before the surgery. Furthermore, the procedure works by lowering the synthesis of ghrelin, a hormone released by the stomach that encourages hunger</p>
          <p>Gastric bypass surgery also helps in lowering the risk of other obesity-related illnesses. Heart disease, hypertension, diabetes, and obstructive sleep apnea are just a few of them. After undergoing gastric bypass, the patient who had previously experienced these problems then reported a dramatic improvement in their condition. In fact, after the surgery, their quality of life has improved by nearly 80% to 90%.
          Gastric bypass surgery is one of the most common weight-loss procedures. It does not require any gastrointestinal system removal. Each year, a lot of patients from overseas seek safe and effective medical care at India's finest bariatric facilities.</p>
          <br></br><br></br>
          <h2 className='heading2'>Gastric Bypass Surgery Recovery</h2>
          <p>Recovery is quite slow. The patient must stay in the hospital for at least 3 to 5 days after gastric bypass surgery. During this time, they are constantly checked, and the general recovery rate is kept track of. Some patients may have severe discomfort and suffering, which is normally controlled with the use of pain-relieving drugs. Patients are advised to a stick to a strict dietary regimen. It allows them to recover rapidly and reduce weight. Before discharge, one of the finest bariatric surgeons or a dietician will create a food plan for the patient to follow. After a week of the treatment, patients are usually allowed to resume their routine activities. Patients must also not lift heavy things and must stay away from any risky physical activity for at least two months after the surgery. Some patients lose weight immediately, while others lose it over the course of a year or two.</p>
          <br></br><br></br>
          <h2 className='heading2'>Who Is Eligible For Gastric Bypass Surgery?</h2>
          <p>Anywhere who is obese and is between the ages of 18 and 60 can get a gastric bypass surgery. These individuals must have a BMI of 45 or more, and obesity-related issues such as hypertension and diabetes must also be reported. Other severe problems include sleep apnea and heart disease.</p>
        </div>

        <input
          className="oncology-input"
          name="tabs"
          type="radio"
          id="tab-4"
          checked={activeTab === 'tab-4'}
          onChange={() => handleTabClick('tab-4')}
        />
        <label className="oncology-label" htmlFor="tab-4">Gastric Band</label>
        <div className="oncology-panel">
          <h1>What Is Gastric Bypass Surgery?</h1>
          <div className='separator'></div>
          <p>The upper section of the stomach is used to create a tiny pouch during the gastric bypass treatment. By linking the pouch produced to the middle part of the small intestine, the rest of the stomach is bypassed. As a result, the food consumed by the patient after surgery enters the intestine. Because the stomach is smaller, the patient experiences an early sense of fullness and eats far less food than before the surgery. Furthermore, the procedure works by lowering the synthesis of ghrelin, a hormone released by the stomach that encourages hunger</p>
          <p>Gastric bypass surgery also helps in lowering the risk of other obesity-related illnesses. Heart disease, hypertension, diabetes, and obstructive sleep apnea are just a few of them. After undergoing gastric bypass, the patient who had previously experienced these problems then reported a dramatic improvement in their condition. In fact, after the surgery, their quality of life has improved by nearly 80% to 90%.
          Gastric bypass surgery is one of the most common weight-loss procedures. It does not require any gastrointestinal system removal. Each year, a lot of patients from overseas seek safe and effective medical care at India's finest bariatric facilities.</p>
          <br></br><br></br>
          <h2 className='heading2'>Gastric Bypass Surgery Recovery</h2>
          <p>Recovery is quite slow. The patient must stay in the hospital for at least 3 to 5 days after gastric bypass surgery. During this time, they are constantly checked, and the general recovery rate is kept track of. Some patients may have severe discomfort and suffering, which is normally controlled with the use of pain-relieving drugs. Patients are advised to a stick to a strict dietary regimen. It allows them to recover rapidly and reduce weight. Before discharge, one of the finest bariatric surgeons or a dietician will create a food plan for the patient to follow. After a week of the treatment, patients are usually allowed to resume their routine activities. Patients must also not lift heavy things and must stay away from any risky physical activity for at least two months after the surgery. Some patients lose weight immediately, while others lose it over the course of a year or two.</p>
          <br></br><br></br>
          <h2 className='heading2'>Who Is Eligible For Gastric Bypass Surgery?</h2>
          <p>Anywhere who is obese and is between the ages of 18 and 60 can get a gastric bypass surgery. These individuals must have a BMI of 45 or more, and obesity-related issues such as hypertension and diabetes must also be reported. Other severe problems include sleep apnea and heart disease.</p>
        </div>
       </div>
    </div>
    <Footer/>
    </>
  );
};

export default Bariatric;
