import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faUserMd, faGlobe, faLightbulb, faCompass, faHandsHelping, faMapMarkedAlt, faStar, faHelicopter, faMobileAlt, faMoneyBill, faUsers, faPlaneDeparture, faHouseMedical } from '@fortawesome/free-solid-svg-icons';
import './OfferService.css';
import { Parallax } from 'react-parallax';
import pic from '../../images/emergency.jpg';

function OfferService() {
  const [modalContent, setModalContent] = useState(null);

  const handleButtonClick = (content) => {
    setModalContent(content);
  };

  const closeModal = () => {
    setModalContent(null);
  };

  return (
    <Parallax strength={400} bgImage={pic}>
      <div className="offer-container py-5 ">
        <h2 className="text-center mb-4 offerobjectives-heading">Explore Our Services</h2>
        <div className="offerobjective-card-carousel">
          <div className="offerobjective-card">
            <div className="offerobjective-card-body">
              <h3 className="offerobjective-card-title">
                <FontAwesomeIcon icon={faHelicopter} className="offerobjectives-icon" />
                <button onClick={() => handleButtonClick("Air Ambulance Facility")} className="offerobjective-button">Air Ambulance Facility</button>
              </h3>
            </div>
          </div>
          <div className="offerobjective-card">
            <div className="offerobjective-card-body">
              <h3 className="offerobjective-card-title">
                <FontAwesomeIcon icon={faMobileAlt} className="offerobjectives-icon" />
                <button onClick={() => handleButtonClick("Collaboration with Renowned Doctors and Hospitals")} className="offerobjective-button">Collaboration with Renowned Doctors </button>
              </h3>
            </div>
          </div>
          <div className="offerobjective-card">
            <div className="offerobjective-card-body">
              <h3 className="offerobjective-card-title">
                <FontAwesomeIcon icon={faHeart} className="offerobjectives-icon" />
                <button onClick={() => handleButtonClick("Health check appointments")} className="offerobjective-button">Health check appointments</button>
              </h3>
            </div>
          </div>
          <div className="offerobjective-card">
            <div className="offerobjective-card-body">
              <h3 className="offerobjective-card-title">
                <FontAwesomeIcon icon={faUserMd} className="offerobjectives-icon" />
                <button onClick={() => handleButtonClick("Specialist Consultation")} className="offerobjective-button">Specialist Consultation</button>
              </h3>
            </div>
          </div>
          <div className="offerobjective-card">
            <div className="offerobjective-card-body">
              <h3 className="offerobjective-card-title">
                <FontAwesomeIcon icon={faGlobe} className="offerobjectives-icon" />
                <button onClick={() => handleButtonClick("International Patient Services")} className="offerobjective-button">International Patient Services</button>
              </h3>
            </div>
          </div>
          <div className="offerobjective-card">
            <div className="offerobjective-card-body">
              <h3 className="offerobjective-card-title">
                <FontAwesomeIcon icon={faMapMarkedAlt} className="offerobjectives-icon" />
                <button onClick={() => handleButtonClick("Medical Tourism")} className="offerobjective-button">Medical Tourism</button>
              </h3>
            </div>
          </div>
          <div className="offerobjective-card">
            <div className="offerobjective-card-body">
              <h3 className="offerobjective-card-title">
                <FontAwesomeIcon icon={faStar} className="offerobjectives-icon" />
                <button onClick={() => handleButtonClick("Quality Healthcare Services")} className="offerobjective-button">Quality Healthcare Services</button>
              </h3>
            </div>
          </div>
          <div className="offerobjective-card">
            <div className="offerobjective-card-body">
              <h3 className="offerobjective-card-title">
                <FontAwesomeIcon icon={faHouseMedical} className="offerobjectives-icon" />
                <button onClick={() => handleButtonClick("Home Healthcare Services")} className="offerobjective-button">Home Healthcare Services</button>
              </h3>
            </div>
          </div>
        </div>
      </div>
      {modalContent && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h2>{modalContent}</h2>
            {/* Add detailed content for the selected service */}
            {modalContent === "Air Ambulance Facility" && (
              <p>
                At ABC Healthcare Solutions, we understand that medical emergencies can arise anywhere, even in the air. Our Air Ambulance Service with a dedicated medical team ensures that critical patients receive the highest level of care while being transported swiftly to medical facilities.
              </p>
            )}
            {modalContent === "Collaboration with Renowned Doctors and Hospitals" && (
              <p>
                The company strives to collaborate closely with renowned doctors and top-tier hospitals to ensure that clients receive the highest quality care available.
              </p>
            )}
            {modalContent === "Health check appointments" && (
              <p>
                One of the primary objectives is to empower individuals with the knowledge and resources necessary to make informed healthcare decisions.
              </p>
            )}
            {modalContent === "Specialist Consultation" && (
              <p>
                Our Specialist Consultation service offers patients access to experienced doctors and specialists in various fields, ensuring comprehensive and personalized healthcare solutions.
              </p>
            )}
            {modalContent === "International Patient Services" && (
              <p>
                Our International Patient Services cater to the needs of patients from around the world, providing seamless coordination of medical appointments, travel arrangements, and accommodation.
              </p>
            )}
            {modalContent === "Medical Tourism" && (
              <p>
                With our Medical Tourism services, patients can access high-quality healthcare services in various destinations, combining medical treatment with travel and leisure experiences.
              </p>
            )}
            {modalContent === "Quality Healthcare Services" && (
              <p>
                We are committed to providing quality healthcare services to our patients, focusing on safety, effectiveness, patient-centeredness, timeliness, efficiency, and equity.
              </p>
            )}
            {modalContent === "Home Healthcare Services" && (
              <p>
                Our Home Healthcare Services bring medical care and support to patients in the comfort of their homes, promoting independence and improving quality of life.
              </p>
            )}
            {/* Add detailed content for other services */}
            <button onClick={closeModal}>Close</button>
          </div>
        </div>
      )}
    </Parallax>
  );
}

export default OfferService;
