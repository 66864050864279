import React, { useState, useEffect } from 'react';
import './Blog.css'; // Import CSS file for styling
import blog2 from '../../images/blog2.png';
import blog3 from '../../images/blog3.png';
import blog4 from '../../images/blog4.png';
import blog5 from '../../images/blog5.png';
import blog6 from '../../images/blog6.png';

const Blog = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Array of card data
  const cards = [
    {
      image: blog2,
      title: 'Personalized Assistance ',
      description: 'Each patient is unique, and so is their medical journey. We take the time to understand individual needs, providing tailored guidance and solutions.',
    },
    {
      image: blog3,
      title: 'Reliability',
      description: ' Our years of experience and successful outcomes showcase our reliability as a go-to resource for medical assistance in Nepal.',
    },
    // Add three more cards
    {
      image: blog4,
      title: 'Transparency',
      description: ' We believe in transparency at every step. From treatment options to cost estimates, we ensure that patients and their families are well-informed throughout the process.',
    },
    {
      image: blog5,
      title: 'Extensive Network',
      description: 'Our well-established connections with Apollo Hospitals and other renowned healthcare institutions empower us to offer a diverse range of medical solutions.',
    },
    {
      image: blog6,
      title: ' Compassionate Care ',
      description: 'We understand the emotional and physical challenges that come with seeking medical treatment.',
    },
  ];

  // Function to move to the next slide
  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === cards.length - 1 ? 0 : prevIndex + 1));
  };

  useEffect(() => {
    // Automatically move to the next slide every 3 seconds
    const interval = setInterval(nextSlide, 3000);
    return () => clearInterval(interval);
  }, [currentIndex]);

  return (
    <div className="blog-card-carousel-container">
      <h1>Reason to Choose  ABC Healthcare Solution</h1>
      <div className="blog-card-carousel">
        {cards.map((card, index) => (
          <div key={index} className={`blog-card ${index === currentIndex ? 'active' : ''}`}>
            <img src={card.image} alt={card.title} />
            <div className="blog-card-content">
              <h2>{card.title}</h2>
              <p>{card.description}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="dots">
        {cards.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === currentIndex ? 'active' : ''}`}
            onClick={() => setCurrentIndex(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default Blog;
