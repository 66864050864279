import React from 'react'
import Footer from '../../elements/Footer/Footer'
import Scroll from '../../elements/Scroll/Scroll'
import ProcedureImage from './ProcedureImage'
import ProcedureOb from './ProcedureOb'
import ProcedureText from './ProcedureText'


const Procedure = () => {
  return (
    <>
    <ProcedureImage/>
    <ProcedureText/>
    <ProcedureOb/>
    <Scroll/>
    <Footer/>
      
    </>
  )
}

export default Procedure
