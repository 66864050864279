import React from 'react'
import './ContactText.css'

const ContactText = () => {
  return (
    <>
    <div className='contact-text-section'>
        <h2>Contact Us</h2>
    </div>
      
    </>
  )
}

export default ContactText
