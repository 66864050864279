import React from 'react'
import LocalImage from './LocalImage'
import Footer from '../../elements/Footer/Footer'
import Scroll from '../../elements/Scroll/Scroll'
import LocalText from './LocalText'
import LocalCard from './LocalCard'

const Local = () => {
  return (
    <>
    <LocalImage/>
    <LocalText/>
    <LocalCard/>
    <Scroll/>
    <Footer/>
      
    </>
  )
}

export default Local
