import React, { useState } from 'react';
import './Oncology.css';
import OrganImage from '../OrganImage';
import Footer from '../../../elements/Footer/Footer';

const Oncology = () => {
  const [activeTab, setActiveTab] = useState('tab-1');

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <>
    <OrganImage/>
    <div className='oncology-container'>
      <div className="oncology-tabs">
        <input
          className="oncology-input"
          name="tabs"
          type="radio"
          id="tab-1"
          checked={activeTab === 'tab-1'}
          onChange={() => handleTabClick('tab-1')}
        />
        <label className="oncology-label" htmlFor="tab-1">Bladder Cancer</label>
        <div className="oncology-panel">
          <h1>Bladder Cancer</h1>
          <div className='separator'></div>
          <p>The bladder is a hollow, muscular, balloon-shaped organ found in the pelvic region. It holds urine produced by the kidneys until it exits the body through the urethra. The malignant growths/ tumors in the tissues of the urinary bladder are referred to as bladder cancer.</p>
          <p>Bladder cancer that starts in cells of the bladder's inner lining is the most prevalent form (transitional cell carcinoma). Squamous cell carcinoma, which starts in the thin, flat cells lining the inside of the bladder, and adenocarcinoma, which starts in the cells that secrete mucus, are the other forms.</p>
          <br></br><br></br>
          <h2 className='heading2'>Symptoms Of Bladder Cancer</h2>
          <div className='separator'></div>
          <p>Haematuria (blood in the urine) is the most prevalent symptom and usually the first indicator of malignancy. It is usually painless and depending on the amount of blood in the urine, the urine may appear pale pink, brown, or crimson (occasionally). The blood may not be visible at times, and the urine may appear to be clear, but urinalysis may reveal minute amounts of blood (urine test). It is also possible that the blood will vanish for weeks after the initial sighting.</p>
          <p>Urinary pattern changes include frequent urination (more than normal), burning or irritation during peeing, and unexpected desires to urinate even when the bladder is not full. These are the symptoms of bladder cancer in its early stages, and they may overlap with those of other illnesses such as urinary tract infection, bladder stones, kidney stones, or an enlarged prostate in men. In any situation, a visit to the doctor for a check-up is required.</p>
          <br></br><br></br>
          <h2 className='heading2'>Diagnosis Of Bladder Cancer</h2>
          <p>The distinct indications of bladder cancer in the urine and bladder are investigated using a variety of assays and techniques. The following are examples of diagnostic assessments:</p>
          <li className='heading-li'>Urinalysis is a simple test that looks for blood and other chemicals in a urine sample. This test employs chemical dipsticks that change colour in the presence of chemicals such as glucose, red blood cells (RBCs), and so on.</li>
          <li className='heading-li'>Urine cytology is the study of a urine sample under a microscope to look for pre-cancerous or malignant cells. For this procedure, the urologist inserts a cystoscope into the opening of the urethra and into the bladder to examine the inner lining of the organ. A camera and a lens are linked to a narrow tube, it is what is called a cystoscope. To make the procedure comfortable and soothing, the patient is usually given a local anaesthetic cream to apply to the urethra.</li>
          <li className='heading-li'>Biopsy: If abnormalities are discovered during a cystoscopy, the patient will be given a biopsy operation termed "Transurethral Resection" of a bladder tumour (TURBT). The tumour (abnormal region) must be removed, and the area must subsequently be tested for malignancy. Because cancer often spreads to multiple areas, multiple samples are taken for testing to see if the cancer has migrated to surrounding bladder muscles.</li>
          <li className='heading-li'>Imaging Scans: For a more exact image of the bladder, these scans allow the specialist to determine whether cancer has progressed to tissues near the bladder, urinary tract, neighbouring lymph nodes, or other organs. Imaging tests include the following:</li>
        </div>

        <input
          className="oncology-input"
          name="tabs"
          type="radio"
          id="tab-2"
          checked={activeTab === 'tab-2'}
          onChange={() => handleTabClick('tab-2')}
        />
        <label className="oncology-label" htmlFor="tab-2">Orthopaedic Oncology</label>
        <div className="oncology-panel">
          <h1>Orthopaedic Surgical Oncology</h1>
          <p>Orthopedic oncology is a subspecialty that involves the diagnosis and treatment of malignant disorders of the musculoskeletal system, such as primary bone cancers. Patients may also develop soft tissue sarcomas or cancers, such as breast, colon, or prostate that have spread to the bone. Orthopedic oncologists treat conditions that occur as a result of cancer or as a side effect of treatment.</p>
          <p>The service is devoted to the diagnosis and treatment of rare but highly challenging tumors of bone. It offers consultations for all bone and soft-tissue tumors, benign and malignant, primary, recurrent, and metastatic as well as tumor-like conditions of bone. It includes the development of limb-sparing surgical strategies for both malignant bone and soft-tissue sarcomas.</p>
          <p>Orthopedic oncologists are specially trained to diagnose and treat cancer patients with musculoskeletal tumors, such as bone, joint, muscular, and soft-tissue tumors. They can perform a variety of procedures to remove tumors, reconstruct the bones if necessary, and help patients restore mobility and/or relieve their symptoms.
          Bone cancer and sarcoma are difficult to treat. While surgeries and targeted procedures can be used to remove tumors or reconstruct bone, patients may be prescribed a combination of treatments and therapies to help restore limb mobility, enhance function and manage pain.</p>
        </div>

        


      
        <input
          className="oncology-input"
          name="tabs"
          type="radio"
          id="tab-4"
          checked={activeTab === 'tab-4'}
          onChange={() => handleTabClick('tab-4')}
        />
        <label className="oncology-label" htmlFor="tab-4">Prostate Cancer</label>
        <div className="oncology-panel">
          <h1>What Is Prostate Cancer?</h1>
          <div className='separator'></div>
          <p>Prostate cancer is most common type of cancer in men. This cancer is more widely known as the disease that causes pain in men at the time of urination, difficulty in urinating, dribbling after urination, frequency of urination and weak urine stream. Men over the age of 50 are at a higher risk of developing prostate cancer. It is important to understand this disease and how it affects the body and how you can prevent it.</p>
          <p>Prostate cancer treatment in India includes a number of therapies, like surgery (prostatectomy), radiation therapy, cryosurgery, and hormone therapy. Prostatectomy is a simple surgical procedure to remove all or part of the prostate gland as a treatment for prostate cancer. It involves removing the cancerous and benign portions of the prostate gland, which helps in curing this disease forever without affecting sexual function.</p>
          <br></br><br></br>
          <h2 className='heading2'>Symptoms Of Prostate Cancer</h2>
          <div className='separator'></div>
          <li className='heading-li'>It is important to know the warning signs of prostate cancer. If you experience any of these symptoms, talk to your doctor: blood in your semen or urine; frequent urination; inability to urinate while standing up; pain or burning during urination or ejaculation.
</li>
          <li className='heading-li'>It is impossible to know when you will need to urinate, but with a Urinary Incontinence Aid, you can be prepared. The Urinary Incontinence Aid helps you stay confident that you will not have an accident by making it easier to start and stop a stream of urine. It also makes it harder for leakage to occur when coughing or laughing.
</li>
          <li className='heading-li'>If you have burning or pain at the time of ejaculation, weak or interrupted urinary stream or if your urination is too frequent or too infrequent, discuss these symptoms with your healthcare provider.
</li>


        </div>
      </div>
    </div>
    <Footer/>
    </>

  );
};

export default Oncology;
